import styled from 'styled-components';
import { Button } from 'shared/ui';

export const Container = styled.div`
    width: 100%;
    max-width: 808px;
`;

export const List = styled.ul`
    width: 100%;
    padding: 0;
    margin: 0 0 4px;

    @media (max-width: 767px) {
        margin: 0 0 14px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
export const Item = styled.li`
    width: 100%;
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        margin-bottom: 16px;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const Actions = styled.div`
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${StyledButton} {
        &:first-child {
            margin-bottom: 40px;
        }
    }
`;

export const AddFileActions = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 40px;
`;

export const Description = styled.article`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 56px;

    @media (max-width: 620px) {
        margin-bottom: 45px;
    }
    @media (max-width: 415px) {
        margin-bottom: 30px;
    }
`;
