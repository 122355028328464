import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    padding: 10px 20px;
    @media (max-width: 767px) {
        padding: 10px 15px;
    }
`;

export const Content = styled.div`
    ${generalFlexStyles};
    flex-direction: column;
    width: 100%;
    background-color: #e7f5fb;
    border-radius: 12px;
    padding: 88px 85px 44px;
    @media (max-width: 767px) {
        padding: 88px 30px 44px;
    }
    @media (max-width: 415px) {
        padding: 50px 10px 40px;
    }
`;

export const Description = styled.article`
    ${generalFlexStyles};
    @media (max-width: 415px) {
        margin-bottom: 30px;
    }
`;

export const ImageContainer = styled.figure`
    ${generalFlexStyles};
    max-width: 1204px;
    position: relative;
    &:after {
        content: url(/assets/example-left-decor.svg);
        width: 74px;
        height: 68px;
        position: absolute;
        left: -20px;
        bottom: -10px;
        @media (max-width: 767px) {
            content: none;
        }
    }
    &:before {
        content: url(/assets/example-right-decor.svg);
        width: 84px;
        height: 83px;
        position: absolute;
        right: -20px;
        top: -50px;
        @media (max-width: 767px) {
            content: none;
        }
    }
`;

export const Image = styled.img`
    width: 100%;
`;
