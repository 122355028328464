import styled from 'styled-components';
import { Button } from 'shared/ui';

export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 20px;
    background: #ffffff;
    border: 1px solid #edeff2;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;

    @media (max-width: 620px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px 45px;
    }
`;

export const LeftContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 620px) {
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
    }
`;

export const RightContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 620px) {
        justify-content: center;
    }
`;

export const Description = styled.div`
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 12px;

    @media (max-width: 620px) {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 12px auto 0;
    }
`;

export const Name = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #121217;
    margin-bottom: 4px;
    max-width: 300px;
    @media (max-width: 820px) {
        max-width: 200px;
    }
    @media (max-width: 620px) {
        max-width: 100%;
        text-align: center;
    }
    @media (min-width: 621px) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const Info = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7e868d;
`;

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #1268fb;
`;

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 256px;

    @media (max-width: 620px) {
        max-width: 100%;
    }
`;

export const CloseButton = styled(Button)`
    margin-left: 15px;
    padding: 5px;
    :hover {
        rect {
            fill: #14161a;
        }
    }

    @media (max-width: 620px) {
        position: absolute;
        top: 15px;
        right: 15px;
    }
`;

export const ProgressLoaderContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    position: relative;
`;

export const ProgressLoader = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
    animation: spin 2.5s infinite;
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: #fff #fff #fff transparent;

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const InboxContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;
export const InboxTitle = styled.span`
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  
`;
export const InboxDescription = styled.span`
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
