import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import getStripe from 'shared/lib/functions/getStripe';
import { PATHS } from 'shared/consts/paths';
import { sessionInfo } from 'entities/Upload/store/upload/reducers/ActionCreator';
import Layout from 'entities/Upload/ui/Layout/Layout';
import FoundNumbers from 'entities/Upload/ui/FoundNumbers/FoundNumbers';
import ModalsPortal from 'widgets/ModalsPortal/ModalsPortal';

const ReportPage: React.FC = () => {
    const { token, stripeKey } = useAppSelector(state => state.uploadReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token) {
            dispatch(sessionInfo(token));
        } else {
            navigate(PATHS.HOME);
        }
    }, []);

    if (!stripeKey) return null;

    return (
        <Layout>
            <Elements stripe={getStripe(stripeKey)}>
                <ModalsPortal />
                <FoundNumbers />
            </Elements>
        </Layout>
    );
};

export default ReportPage;
