import styled, { css } from 'styled-components';
import { IRoundCountStylesProps, RoundType } from './RoundCount.types';

export const Count = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-align: center;
`;

export const Container = styled.div<IRoundCountStylesProps>`
    width: ${({ size }) => size ?? '32px'};
    height: ${({ size }) => size ?? '32px'};
    min-width: ${({ size }) => size ?? '32px'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    ${({ type }) =>
        (type === RoundType.ERROR &&
            css`
                ${Count} {
                    color: #e11900;
                }
                background: rgba(225, 25, 0, 0.08);
            `) ||
        (type === RoundType.SUCCESS &&
            css`
                ${Count} {
                    color: #068a5a;
                }
                background: rgba(0, 172, 110, 0.14);
            `)}
`;
