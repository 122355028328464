import React, {useEffect} from 'react';
import { useAppSelector } from 'shared/lib/hooks/redux';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import {
    ENV_DEVELOPMENT, LIVE_ACCOUNT_FORM_GUID,
    LIVE_ACCOUNT_PORTAL_ID,
    MIGRATION_TEST_ACCOUNT_FORM_GUID,
    MIGRATION_TEST_ACCOUNT_PORTAL_ID
} from "shared/consts/common";
import { Title } from 'shared/ui';
import Report from 'entities/Upload/ui/Report/Report';
import StepsCount from 'entities/Upload/ui/StepsCount/StepsCount';
import { ReportType } from 'entities/Upload/ui/Report/Report.types';
import {
    Description,
    Container,
    InfoTable,
    Column,
    Count,
    Name,
    Separator,
    ReportContainer,
    HubspotFormContainer,
} from './FoundNumbers.style';

const FoundNumbers = () => {
    const { duplicates_rows_count, failed_rows_count, success_rows_count, total_rows_count } = useAppSelector(
        state => state.uploadReducer,
    );
    const { hubspotData } = useAppSelector(state => state.uploadReducer);
    const { type, email, name } = hubspotData;

    let portalId;
    let formGuid;

    if (process.env.REACT_APP_ENV === ENV_DEVELOPMENT) {
        portalId = MIGRATION_TEST_ACCOUNT_PORTAL_ID;
        formGuid = MIGRATION_TEST_ACCOUNT_FORM_GUID;
    } else {
        portalId = LIVE_ACCOUNT_PORTAL_ID;
        formGuid = LIVE_ACCOUNT_FORM_GUID;
    }

    const { formCreated } = useHubspotForm({
        portalId,
        formId: formGuid,
        target: '#hubspot',
    });

    const handleSendDataToHubspot = () => {
        setTimeout(() => {
            const submitInputHS = document.getElementsByClassName('hs-button primary large');

            (submitInputHS[0] as HTMLInputElement).click();
        }, 300);
    };

    const sendDataToHubspot = () => {
        if (formCreated) {
            const emailInputHS = document.getElementsByName('email');
            const nameInputHS = document.getElementsByName('firstname');

            if (emailInputHS.length) {
                (emailInputHS[0] as HTMLInputElement).value = email;
                emailInputHS[0].dispatchEvent(new Event('input', { bubbles: true }));

                if (type === ReportType.BASIC) {
                    handleSendDataToHubspot();
                }
                if (type === ReportType.FULL) {
                    if (nameInputHS.length) {
                        if (typeof name === "string") {
                            (nameInputHS[0] as HTMLInputElement).value = name;
                        }
                        nameInputHS[0].dispatchEvent(new Event('input', { bubbles: true }));
                        handleSendDataToHubspot();
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (type === ReportType.BASIC && email) {
            sendDataToHubspot();
        }

        if (type === ReportType.FULL && email && name) {
            sendDataToHubspot();
        }
    }, [type, email, name])

    return (
        <Container>
            <StepsCount>One last step</StepsCount>
            <Description>
                <Title text={`We found ${success_rows_count} phone numbers!`} />
            </Description>
            <InfoTable>
                <Column>
                    <Count>{total_rows_count}</Count>
                    <Name>Total</Name>
                </Column>
                <Separator />
                <Column>
                    <Count>{success_rows_count}</Count>
                    <Name>Success</Name>
                </Column>
                <Separator />
                <Column>
                    <Count>{duplicates_rows_count}</Count>
                    <Name>Duplicates</Name>
                </Column>
                <Separator />
                <Column>
                    <Count>{failed_rows_count}</Count>
                    <Name>Failed</Name>
                </Column>
            </InfoTable>
            <ReportContainer>
                <Report type={ReportType.BASIC} />
                <Report type={ReportType.FULL} />
            </ReportContainer>
            <HubspotFormContainer id="hubspot" />
        </Container>
    );
};

export default FoundNumbers;
