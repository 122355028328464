import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 256px;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #dae7ed;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
    h3 {
        max-width: 230px;
    }
    p {
        max-width: 285px;
    }
`;
