import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IUploadedFile,
    IResponseStatus,
    IUploadState,
    ISessionInfo,
    IToken,
    IResponseDownloadInfo,
    IHubspotData
} from '../models/IUpload';
import {
    addFile,
    sessionInfo,
    checkUploadStatus,
    updatePhoneKey,
    requestBasicReport,
    removeFile,
    sessionNew,
    getDownloadInfo,
    sendPaymentsData,
} from './ActionCreator';

const initialState: IUploadState = {
    token: null,
    isLoading: false,
    loadedFiles: [],
    percentage: null,
    duplicates_rows_count: 0,
    failed_rows_count: 0,
    success_rows_count: 0,
    total_rows_count: 0,
    status_group: '',
    send_to: null,
    type: null,
    zip_file: '',
    coupon: null,
    paymentStatus: null,
    isLoadingPayments: false,
    stripeKey: '',
    hubspotData: {
        type: null,
        email: '',
        name: ''
    }
};

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        removeLoadedFile: (state, action: PayloadAction<number>) => {
            state.loadedFiles = state.loadedFiles.filter(item => item.id !== action.payload);
        },

        sendToEmail: (state, action: PayloadAction<string>) => {
            state.send_to = action.payload;
        },

        sendDataToHubspot: (state, action: PayloadAction<IHubspotData>) => {
            state.hubspotData = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(sessionNew.fulfilled, (state, action: PayloadAction<IToken>) => {
            state.isLoading = false;
            state.token = action.payload.token;
            state.loadedFiles = [];
            state.percentage = null;
            state.duplicates_rows_count = 0;
            state.failed_rows_count = 0;
            state.success_rows_count = 0;
            state.total_rows_count = 0;
            state.status_group = '';
            state.send_to = null;
            state.type = null;
            state.zip_file = '';
            state.coupon = null;
            state.paymentStatus = null;
            state.isLoadingPayments = false;
            state.stripeKey = '';
        });
        builder.addCase(sessionNew.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(sessionNew.rejected, state => {
            state.isLoading = false;
            state.token = null;
        });

        builder.addCase(addFile.fulfilled, (state, action: PayloadAction<IUploadedFile | IResponseStatus>) => {
            state.isLoading = false;
        });
        builder.addCase(addFile.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(addFile.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(sessionInfo.fulfilled, (state, action: PayloadAction<ISessionInfo>) => {
            state.isLoading = false;
            state.status_group = action.payload.status_group;

            if ('uploads' in action.payload.details) {
                state.loadedFiles = action.payload.details.uploads;
            }

            if ('percentage' in action.payload.details) {
                if (Number(action.payload.details.percentage) < 1) {
                    state.percentage = 1;
                }

                if (action.payload.details.percentage === '0.00') {
                    state.percentage = 1;
                } else {
                    state.percentage = Number(action.payload.details.percentage);
                }
            }

            if ('duplicates_rows_count' in action.payload.details) {
                state.duplicates_rows_count = action.payload.details.duplicates_rows_count;
            }

            if ('failed_rows_count' in action.payload.details) {
                state.failed_rows_count = action.payload.details.failed_rows_count;
            }

            if ('success_rows_count' in action.payload.details) {
                state.success_rows_count = action.payload.details.success_rows_count;
            }

            if ('total_rows_count' in action.payload.details) {
                state.total_rows_count = action.payload.details.total_rows_count;
            }

            if ('coupon' in action.payload.details) {
                state.coupon = action.payload.details.coupon;
            }

            if ('stripe_key' in action.payload.details) {
                state.stripeKey = action.payload.details.stripe_key;
            }
        });
        builder.addCase(sessionInfo.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(sessionInfo.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(checkUploadStatus.fulfilled, (state, action: PayloadAction<IResponseStatus>) => {
            state.isLoading = false;
        });
        builder.addCase(checkUploadStatus.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(checkUploadStatus.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(removeFile.fulfilled, (state, action: PayloadAction<IResponseStatus>) => {
            state.isLoading = false;
        });
        builder.addCase(removeFile.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(removeFile.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(updatePhoneKey.fulfilled, (state, action: PayloadAction<IResponseStatus | IUploadedFile>) => {
            state.isLoading = false;
        });
        builder.addCase(updatePhoneKey.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(updatePhoneKey.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(requestBasicReport.fulfilled, (state, action: PayloadAction<IResponseStatus>) => {
            state.isLoading = false;
        });
        builder.addCase(requestBasicReport.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(requestBasicReport.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(
            getDownloadInfo.fulfilled,
            (state, action: PayloadAction<IResponseStatus | IResponseDownloadInfo>) => {
                state.isLoading = false;
                if ('type' in action.payload) {
                    state.type = action.payload.type;
                }

                if ('zip_file' in action.payload) {
                    state.zip_file = action.payload.zip_file;
                }
            },
        );
        builder.addCase(getDownloadInfo.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(getDownloadInfo.rejected, state => {
            state.isLoading = false;
        });

        builder.addCase(sendPaymentsData.fulfilled, (state, action: PayloadAction<IResponseStatus>) => {
            state.isLoadingPayments = false;

            if (action.payload.status && action.payload.message) {
                state.paymentStatus = action.payload;
            }
        });
        builder.addCase(sendPaymentsData.pending, state => {
            state.isLoadingPayments = true;
        });
        builder.addCase(sendPaymentsData.rejected, state => {
            state.isLoadingPayments = false;
        });
    },
});

export default uploadSlice.reducer;
