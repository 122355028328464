import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'shared/consts/paths';
import { TABLET_WIDTH, MOBILE_WIDTH } from 'shared/consts/common';
import useWindowResizeObserver from 'shared/lib/hooks/useWindowResizeObserver';
import MobileNavigation from 'widgets/MobileNavigation/MobileNavigation';
import {
    Container,
    HeaderLogo,
    LinkHeaderLogo,
    BrandLogo,
    Description,
    Image,
    HeaderNavigation,
    NavList,
    NavItem,
    Link,
} from './Header.style';
import logoPhoneCheckr from 'shared/assets/phone-checkr.svg';
import logoSalesmsg from 'shared/assets/salesmsg.png';

const Header: React.FC = () => {
    const { width } = useWindowResizeObserver();
    const location = useLocation();

    const navigateToSalesmsg = () => {
        ReactGA.event({
            category: 'PhoneCheckr',
            action: 'Go to Salesmsg',
        });
    };

    return (
        <Container>
            {location.pathname === PATHS.HOME ? (
                <LinkHeaderLogo smooth to={PATHS.HOME_HEAD}>
                    <Image src={logoPhoneCheckr} alt="logo PhoneCheckr" />
                </LinkHeaderLogo>
            ) : (
                <HeaderLogo to={PATHS.HOME}>
                    <Image src={logoPhoneCheckr} alt="logo PhoneCheckr" />
                </HeaderLogo>
            )}
            {width > TABLET_WIDTH && (
                <HeaderNavigation>
                    <NavList>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_UPLOAD}>
                                Upload
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_HOW_IT_WORK}>
                                How it works
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_FAQS}>
                                FAQs
                            </Link>
                        </NavItem>
                    </NavList>
                </HeaderNavigation>
            )}
            {width > MOBILE_WIDTH && (
                <BrandLogo>
                    <Description>made by</Description>
                    <a
                        onClick={navigateToSalesmsg}
                        href="https://www.salesmessage.com/?utm_source=phonecheckr&utm_medium=link&utm_campaign=main"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Image src={logoSalesmsg} alt="logo Salesmsg" />
                    </a>
                </BrandLogo>
            )}
            {width <= TABLET_WIDTH && <MobileNavigation />}
        </Container>
    );
};

export default Header;
