import React, { memo } from 'react';
import { ITitleProps } from './Title.types';
import { Container } from './Title.style';

const TitleComponent: React.FC<ITitleProps> = ({ text, color, size, margin, textAlign, as }) => {
    return (
        <>
            {text && (
                <Container as={as} color={color} size={size} margin={margin} textAlign={textAlign}>
                    {text}
                </Container>
            )}
        </>
    );
};

export const Title = memo(TitleComponent);
