import styled from 'styled-components';
import { Button } from 'shared/ui';

export const Container = styled.div`
    width: 100%;
    max-width: 808px;
    padding-top: 25px;
`;

export const CompletedLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    margin: 0 auto 24px;
    border: 2px solid #edeff2;
    border-radius: 100%;
`;

export const Description = styled.article`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    @media (max-width: 415px) {
        margin-bottom: 50px;
    }
`;

export const UploadButton = styled(Button)`
    margin: 0 auto;
`;
