import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Input, VectorIcon } from 'shared/ui';
import { ButtonColorType, ButtonFormType } from 'shared/ui/Button/Button.types';
import { IUploaderDropzoneProps } from './UploaderDropzone.types';
import { Container, Description, ActionContainer, InfoContainer, Title } from './UploaderDropzone.style';

export const UploaderDropzone: React.FC<IUploaderDropzoneProps> = ({ config, onDrop, isShowInformation }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: config,
        onDrop: onDrop,
    });

    return (
        <Container isShowInformation={isShowInformation} isActive={isDragActive} {...getRootProps()}>
            <ActionContainer>
                <Input {...getInputProps()} />
                <Button colorType={ButtonColorType.BLUE} formType={ButtonFormType.ROUND} width="80px" height="80px">
                    {isDragActive ? (
                        <VectorIcon icon="load-arrow" size={[32, 32]} />
                    ) : (
                        <VectorIcon icon="add" size={[32, 32]} />
                    )}
                </Button>
            </ActionContainer>
            {isShowInformation && (
                <InfoContainer>
                    <Title>browse or drag and drop a file here</Title>
                    <Description>Supported file type: csv</Description>
                </InfoContainer>
            )}
        </Container>
    );
};
