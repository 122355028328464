import React, { ForwardedRef, forwardRef, memo } from 'react';
import { IInputProps } from './Input.types';
import { Container } from './Input.style';

export const InputComponent = forwardRef(
    (
        { name, type = 'text', value, min, disabled, checked, accept, multiple, ...props }: IInputProps,
        ref: ForwardedRef<HTMLInputElement>,
    ): JSX.Element => {
        return (
            <Container
                type={type}
                name={name}
                value={value}
                min={min}
                disabled={disabled}
                checked={checked}
                accept={accept}
                multiple={multiple}
                ref={ref}
                {...props}
            />
        );
    },
);

InputComponent.displayName = 'Input';

export const Input = memo(InputComponent);
