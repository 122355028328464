import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalsState } from '../models/IModals';

const initialState: IModalsState = {
    modalType: null,
    isShow: false,
    id: null,
    value: null,
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        showModal(state, action: PayloadAction<{ modalType: string; id?: number | null; value?: string }>) {
            state.modalType = action.payload.modalType;
            state.isShow = true;
            state.id = action.payload.id;
            state.value = action.payload.value;
        },
        closeModals(state) {
            state.modalType = '';
            state.isShow = false;
            state.id = null;
            state.value = null;
        },
    },
});

export default modalsSlice.reducer;
