import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { createGlobalStyle } from 'styled-components';
import { setupStore } from 'shared/store/store';
import { PATHS } from 'shared/consts/paths';
import HomePage from 'pages/HomePage/HomePage';
import UploadPage from 'pages/UploadPage/UploadPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import TermsOfUsePage from 'pages/TermsOfUsePage/TermsOfUsePage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import DownloadPage from 'pages/DownloadPage/DownloadPage';
import CheckPage from 'pages/CheckPage/CheckPage';
import ReportPage from 'pages/ReportPage/ReportPage';
import CouponPage from 'pages/CouponPage/CouponPage';
import Layout from 'widgets/Layout/Layout';
import ScrollToTop from 'shared/lib/hoc/ScrollToTop';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: -apple-system, 'Inter', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  a {
    cursor: pointer;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
  
  ul, 
  ol {
    list-style: none;
  }

  p {
    font-weight: 400;
  }

  input,
  textarea,
  button,
  a {
    &:focus {
      outline: none;
      text-decoration: none;
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  * {
    box-sizing: border-box;
  }
`;

const App = () => {
    return (
        <HubspotProvider>
            <Provider store={setupStore()}>
                <GlobalStyle />
                <ScrollToTop>
                    <Routes>
                        <Route path={PATHS.HOME} element={<Layout />}>
                            <Route path={PATHS.ERROR_PAGE} element={<ErrorPage />} />
                            <Route index element={<HomePage />} />
                            <Route path={PATHS.UPLOAD} element={<UploadPage />} />
                            <Route path={PATHS.CHECK} element={<CheckPage />} />
                            <Route path={PATHS.REPORT} element={<ReportPage />} />
                            <Route path={PATHS.COUPON} element={<CouponPage />} />
                            <Route path={PATHS.TERMS_OF_USE} element={<TermsOfUsePage />} />
                            <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
                            <Route path={PATHS.DOWNLOAD} element={<DownloadPage />} />
                        </Route>
                    </Routes>
                </ScrollToTop>
            </Provider>
        </HubspotProvider>
    );
};

export default App;
