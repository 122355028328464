import styled from 'styled-components';

export const Container = styled.input`
    &:disabled {
        cursor: no-drop;
    }
    &:-internal-autofill-selected {
        background-color: #ffffff;
    }
    &::placeholder {
        color: rgba(181, 181, 181, 1);
    }
    &[type='checkbox'] {
        height: auto;
        width: auto;
    }
`;
