import React from 'react';
import uuid from 'react-uuid';
import { FeaturesCard } from 'shared/ui';
import { Container, Content, Decorator, InnerContent, Item, List } from './FeaturesSection.style';

const features = [
    {
        icon: 'identify-phone',
        title: 'Identify phone number’s carrier and type',
        description: 'Discover which numbers are mobile, landline or VOIP.',
    },
    {
        icon: 'validate-numbers',
        title: 'Validate and format the numbers',
        description: 'Check junks numbers and identify local-friendly number formats.',
    },
    {
        icon: 'improve-message',
        title: 'Improve message deliverability',
        description: 'Pick the right medium for your message to ensure it’s delivered.',
    },
];

const FeaturesSection: React.FC = () => {
    return (
        <Container>
            <Content>
                <Decorator>
                    <InnerContent>
                        <List>
                            {features.map(item => {
                                return (
                                    <Item key={uuid()}>
                                        <FeaturesCard
                                            icon={item.icon}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </Item>
                                );
                            })}
                        </List>
                    </InnerContent>
                </Decorator>
            </Content>
        </Container>
    );
};

export default FeaturesSection;
