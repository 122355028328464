import React from 'react';
import { Title } from 'shared/ui';
import exampleImage from 'shared/assets/example-image.png';
import { Container, Content, Description, ImageContainer, Image } from './ExampleSection.style';

const ExampleSection: React.FC = () => {
    return (
        <Container>
            <Content>
                <Description>
                    <Title as="h2" text="You’ll get a report like this!" />
                </Description>
                <ImageContainer>
                    <Image src={exampleImage} alt="You’ll get a report like this!" />
                </ImageContainer>
            </Content>
        </Container>
    );
};

export default ExampleSection;
