import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    padding: 10px 20px;
    @media (max-width: 767px) {
        padding: 10px 15px;
    }
    @media (max-width: 415px) {
        padding: 10px 0;
    }
`;

export const Content = styled.div`
    ${generalFlexStyles};
    flex-direction: column;
    width: 100%;
    background-color: #fbfbfb;
    border-radius: 12px;
    padding: 88px 0;
    @media (max-width: 767px) {
        border-radius: 0;
    }
`;

export const Description = styled.article`
    ${generalFlexStyles};
    margin-bottom: 88px;
    @media (max-width: 415px) {
        margin-bottom: 80px;
    }
`;

export const Info = styled.div`
    ${generalFlexStyles};
    padding: 0 10px;
    @media (max-width: 1199px) {
        flex-direction: column;
    }
    @media (max-width: 415px) {
        padding: 0 15px;
    }
`;

export const Image = styled.img`
    width: 100%;
    max-width: 528px;
    margin-right: 160px;
    @media (max-width: 1199px) {
        margin: 0 auto 90px;
    }
    @media (max-width: 415px) {
        margin: 0 auto 25px;
    }
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;
`;

export const Item = styled.li`
    display: flex;
    margin: 0 0 32px;
    padding: 0;
    &:last-child {
        @media (max-width: 767px) {
            margin: 0;
        }
    }
`;

export const TextContainer = styled.div`
    max-width: 405px;
    margin-left: 16px;
`;
