import { useMemo } from 'react';

const useGetCost = (count: number) => {
    return useMemo(() => {
        if (count) {
            const result = count * 0.01;
            const cost = Number(result.toFixed(2));

            if (cost < 5) {
                return cost + 1;
            } else {
                return cost;
            }
        }

        return 0;
    }, [count]);
};

export default useGetCost;
