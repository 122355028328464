import styled, { css } from 'styled-components';
import { AlertType, IAlertProps } from './Alert.types';

export const CloseButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #fff;
    margin-left: auto;
    svg {
        g {
            rect {
                fill: #ffffff;
            }
        }
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const Container = styled.div<IAlertProps>`
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${({ type }) =>
        (type === AlertType.SUCCESS &&
            css`
                background-color: #5ec877;
                ${CloseButton} {
                    background: rgba(77, 162, 97, 1);
                }
            `) ||
        (type === AlertType.ERROR &&
            css`
                background-color: #d9534f;
                ${CloseButton} {
                    background: rgba(10, 10, 10, 0.2);
                }
            `) ||
        (type === AlertType.WARNING &&
            css`
                background-color: #f0ad4e;
                ${CloseButton} {
                    background: rgba(10, 10, 10, 0.2);
                }
            `)}
}

`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 21px;
    color: #ffffff;
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    padding: 0;
    margin: 0;
`;
