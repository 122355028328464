import { ValueOf } from 'shared/types/general';

export const AlertType = {
    ERROR: 'Error',
    SUCCESS: 'Success',
    WARNING: 'Warning',
} as const;

type AlertType = ValueOf<typeof AlertType>;

export interface IAlertProps {
    description?: string;
    title?: string;
    type?: AlertType;
    closeToast?: () => void;
}
