import React from 'react';
import uuid from 'react-uuid';
import { MarkerType } from 'shared/ui/Marker/Marker.types';
import { Marker, PricingTable } from 'shared/ui';
import {
    Container,
    LabelContainer,
    Label,
    ValueContainer,
    Value,
    SubValue,
    Description,
    Title,
} from './PricingSection.style';

const pricingList = [
    {
        id: uuid(),
        title: 'Validation & Formatting',
        basicReportStatus: true,
        fullReportStatus: true,
    },
    {
        id: uuid(),
        title: 'Country Code',
        basicReportStatus: true,
        fullReportStatus: true,
    },
    {
        id: uuid(),
        title: 'Carrier Name',
        basicReportStatus: false,
        fullReportStatus: true,
    },
    {
        id: uuid(),
        title: 'Carrier Type',
        basicReportStatus: false,
        fullReportStatus: true,
    },
    {
        id: uuid(),
        title: 'Mobile Country Code',
        basicReportStatus: false,
        fullReportStatus: true,
    },
    {
        id: uuid(),
        title: 'Mobile Network Code',
        basicReportStatus: false,
        fullReportStatus: true,
    },
];
const PricingSection: React.FC = () => {
    return (
        <Container>
            <PricingTable
                data={pricingList}
                columns={[
                    {
                        label: '',
                        textAlign: 'left',
                        templateHead: column => <div>{column.label}</div>,
                        templateRow: row => <Title>{row.title}</Title>,
                    },
                    {
                        label: (
                            <LabelContainer>
                                <Label>Basic Report</Label>
                                <ValueContainer>
                                    <Value>Free</Value>
                                    <SubValue>/ up to 1,000 records</SubValue>
                                </ValueContainer>
                                <Description>Best for validating inputs</Description>
                            </LabelContainer>
                        ),
                        textAlign: 'left',
                        templateRow: row => (
                            <Marker
                                markerSize={24}
                                status={row.basicReportStatus ? MarkerType.COMPLETED : MarkerType.NOT_PROCESSED}
                            />
                        ),
                    },
                    {
                        label: (
                            <LabelContainer>
                                <Label>Full Report</Label>
                                <ValueContainer>
                                    <Value>$0.01</Value>
                                    <SubValue>/ lookup</SubValue>
                                </ValueContainer>
                                <Description>Best for ensuring message delivery</Description>
                            </LabelContainer>
                        ),
                        textAlign: 'left',
                        templateRow: row => (
                            <Marker
                                markerSize={24}
                                status={row.fullReportStatus ? MarkerType.COMPLETED : MarkerType.NOT_PROCESSED}
                            />
                        ),
                    },
                ]}
            />
        </Container>
    );
};

export default PricingSection;
