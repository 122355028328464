import React, { memo, useState } from 'react';
import { VectorIcon } from 'shared/ui';
import { IAccordionProps } from './Accordion.types';
import { Container, InnerContainer, Content, Header, Title, Icon } from './Accordion.style';

const AccordionComponent: React.FC<IAccordionProps> = ({ title = '', isActive = false, children = null }) => {
    const [isOpen, setIsOpen] = useState(isActive);

    const handleToggleAccordion = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <Container>
            <InnerContainer isActive={isOpen}>
                <Header onClick={handleToggleAccordion}>
                    <Title>{title}</Title>
                    <Icon>
                        <VectorIcon size={[16, 16]} icon={isOpen ? 'minus' : 'plus'} />
                    </Icon>
                </Header>
                <Content>{isOpen && <>{children}</>}</Content>
            </InnerContainer>
        </Container>
    );
};

export const Accordion = memo(AccordionComponent);
