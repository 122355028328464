import styled, { css } from 'styled-components';
import { ModalInterface } from './Modal.types';

export const Fade = styled.div<ModalInterface>`
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    position: fixed;
    transition: all 0.2s ease-in-out;
    z-index: 999;
`;

export const Background = styled.div`
    background: #121217;
    opacity: 0.2;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    pointer-events: auto;
    background: rgba(255, 255, 255, 1);
    background-clip: padding-box;
    border: 1px solid #edeff2;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
    outline: 0;

    @media (max-width: 520px) {
        width: 100%;
    }
`;

export const Container = styled.div<ModalInterface>`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    padding-top: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 40;
    ${({ isDanger }) =>
        isDanger &&
        css`
            ${Content} {
                overflow: hidden;
                background: repeating-linear-gradient(-45deg, #d9534f, #d9534f 30px, #dc5f5c 30px, #dc5f5c 60px);
            }
        `}
`;
