import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { usePostHog } from 'posthog-js/react'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import validateEmail from 'shared/lib/functions/validateEmail';
import { requestBasicReport } from 'entities/Upload/store/upload/reducers/ActionCreator';
import { uploadSlice } from 'entities/Upload/store/upload/reducers/Upload';
import { modalsSlice } from 'shared/store/modals/redusers/Modals';
import { PATHS } from 'shared/consts/paths';
import { IReportProps, ReportType } from './Report.types';
import { MarkerType } from 'shared/ui/Marker/Marker.types';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import * as types from 'shared/consts/modalsTypes';
import { Button, Marker } from 'shared/ui';
import {
    Container,
    Content,
    LabelContainer,
    Label,
    SubValue,
    Value,
    ValueContainer,
    List,
    Item,
    Name,
    Actions,
    InputCroup,
    EmailInput,
    Error,
    CloseButton,
    ErrorMessage,
} from './Report.style';

const Report: React.FC<IReportProps> = ({ type }) => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const { token } = useAppSelector(state => state.uploadReducer);
    const { sendToEmail, sendDataToHubspot } = uploadSlice.actions;
    const { showModal } = modalsSlice.actions;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const posthog = usePostHog();

    const isBasic = type === ReportType.BASIC;
    const basicReportList = ['Validation & Formatting', 'Country Code'];
    const fullReportList = [
        'Validation & Formatting',
        'Country Code',
        'Carrier Name',
        'Carrier Type',
        'Mobile Country Code',
        'Mobile Network Code',
    ];

    const list = isBasic ? basicReportList : fullReportList;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;

        setEmail(value.trim());
    };

    const sendEventToGA = (type: string) => {
        ReactGA.event({
            category: 'PhoneCheckr',
            action: 'Email Submitted',
            label: type,
        });
    };

    const clearErrors = (): void => setError(false);

    const downloadHandler = (): void => {
        if (!validateEmail(email)) {
            setError(true);
        } else if (token) {
            dispatch(
                sendDataToHubspot({
                    type: ReportType.BASIC,
                    email,
                }),
            );
            const sendBasicReportStatus = dispatch(
                requestBasicReport({
                    token,
                    email,
                }),
            );

            sendBasicReportStatus.then(() => {
                dispatch(sendToEmail(email));
                sendEventToGA('Free');
                posthog.identify(
                    email,
                    { email: email }
                );
                navigate(PATHS.COUPON);
            });
        }
    };

    const paymentHandler = (): void => {
        if (!validateEmail(email)) {
            setError(true);
        } else {
            sendEventToGA('Paid');
            posthog.identify(
                email,
                { email: email }
            );
            dispatch(sendToEmail(email));
            dispatch(
                showModal({
                    modalType: types.MODAL_TYPE_PAYMENT,
                    value: email,
                }),
            );
        }
    };

    return (
        <Container>
            <Content>
                <LabelContainer>
                    <Label>{isBasic ? 'Basic Report' : 'Full Report'}</Label>
                    <ValueContainer>
                        <Value>{isBasic ? 'Free' : '$0.01'}</Value>
                        <SubValue>{isBasic ? '/ up to 1,000 records' : '/ lookup'}</SubValue>
                    </ValueContainer>
                </LabelContainer>
                <List>
                    {list.map(item => {
                        return (
                            <Item key={uuid()}>
                                <Marker
                                    markerSize={16}
                                    icon="white-check-small"
                                    iconSize={[8, 8]}
                                    status={MarkerType.COMPLETED}
                                />
                                <Name>{item}</Name>
                            </Item>
                        );
                    })}
                </List>
            </Content>
            <Actions>
                <InputCroup>
                    <EmailInput
                        placeholder="Enter your email"
                        type="text"
                        value={email}
                        onChange={handleChange}
                        onFocus={clearErrors}
                    />
                    {error && (
                        <Error>
                            <CloseButton onClick={clearErrors} />
                            <ErrorMessage>Please provide a valid email </ErrorMessage>
                        </Error>
                    )}
                </InputCroup>
                <Button
                    colorType={ButtonColorType.BLUE}
                    width="100%"
                    onClick={isBasic ? downloadHandler : paymentHandler}
                >
                    {isBasic ? 'Get reports' : 'Continue'}
                </Button>
            </Actions>
        </Container>
    );
};

export default Report;
