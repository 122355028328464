import React, { useState, useEffect, memo } from 'react';
import { UploaderDropzone } from './UploaderDropzone/UploaderDropzone';
import { IUploaderProps } from './Uploader.types';

const UploaderComponent: React.FC<IUploaderProps> = ({ isShowInformation, config, onFilesUploadedAll }) => {
    const [files, setFiles] = useState<File[]>([]);

    const handleDrop = (acceptedFiles: File[]) => {
        if (!files.length) {
            const newFiles = [...files, ...acceptedFiles];

            setFiles(newFiles);
        } else {
            const newFiles = [...acceptedFiles];

            setFiles(newFiles);
        }
    };

    useEffect(() => {
        if (files.length) {
            onFilesUploadedAll && onFilesUploadedAll(files);
        }
    }, [files]);

    return <UploaderDropzone isShowInformation={isShowInformation} config={config} onDrop={handleDrop} />;
};

export const Uploader = memo(UploaderComponent);
