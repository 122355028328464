import React from 'react';
import uuid from 'react-uuid';
import { Accordion, Title, SubTitle } from 'shared/ui';
import { Container, Content, Description, FaqList, FaqItem, AccordionContent } from './FAQSection.style';

const faqList = [
    {
        title: 'How can I find if the phone numbers are mobile or landline?',
        content:
            'PhoneCheckr looks through a national phone database to determine the Carrier Name, Carrier Type, Mobile Network Code, and Mobile Country Code. With the Carrier Type we will provide you the details around the phone number so you’ll know whether it’s a landline, mobile, VOIP, or invalid number.',
    },
    {
        title: 'What can I do with my document report provided by Phonecheckr?',
        content:
            'PhoneCheckr gives you the ability to now know the details behind a number. If you plan on making calls, sending texts, and reaching out to these contacts, you’ll know which numbers are legitimate and valid.',
    },
    {
        title: 'What is the report price?',
        content:
            'You can validate and format your numbers with country code for free, howver if you’d like to know the details behind each number, you can choose to get the full report to get more of the details.',
    },
    {
        title: 'Which payment methods do you accept?',
        content:
            'We use Stripe for secure payment processing and accept VISA, Mastercard, American Express, and Discover debit and credit cards.',
    },
];

const FaqSection: React.FC = () => {
    return (
        <Container id="section-faqs">
            <Content>
                <Description>
                    <Title as="h2" text="Frequently asked questions" margin="0 0 8px 0" />
                    <SubTitle text="Have questions? We’re here to help." />
                </Description>
                <FaqList>
                    {faqList.map(({ title, content }) => {
                        return (
                            <FaqItem key={uuid()}>
                                <Accordion title={title}>
                                    <AccordionContent>{content}</AccordionContent>
                                </Accordion>
                            </FaqItem>
                        );
                    })}
                </FaqList>
            </Content>
        </Container>
    );
};

export default FaqSection;
