import React, { memo } from 'react';
import { IRoundCountProps } from './RoundCount.types';
import { Container, Count } from './RoundCount.style';

const RoundCountComponent: React.FC<IRoundCountProps> = ({ count, type, size }) => {
    return (
        <Container type={type} size={size}>
            <Count>{count}</Count>
        </Container>
    );
};

export const RoundCount = memo(RoundCountComponent);
