import styled from 'styled-components';

export const Head = styled.section`
    width: 100%;
    margin: 100px auto 48px;
    @media (max-width: 767px) {
        margin: 60px auto 28px;
    }
`;

export const Content = styled.section`
    width: 100%;
    max-width: 680px;
    margin: 0 auto 80px;
`;

export const Container = styled.div`
    width: 100%;
    padding: 0 20px;
`;

export const Text = styled.p`
    font-size: 18px;
    line-height: 1.56em;
    margin-bottom: 20px;
    color: rgb(18, 18, 23);
    a {
        background-color: transparent;
        transition: 0.3s;
        color: #1d96f3;
        text-decoration: none;
        &:hover {
            color: #0981dc;
        }
    }
    @media (max-width: 767px) {
        font-size: 17px;
    }
`;
