import styled from 'styled-components';
import { Button } from 'shared/ui';

export const Container = styled.div`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;
