import styled from 'styled-components';
import { StylesConfig } from 'react-select';
import { stylesSelect } from 'shared/ui/Select/Select.style';
import { Button, Input } from 'shared/ui';

export const Body = styled.div`
    position: relative;
    width: 500px;

    @media (max-width: 520px) {
       width: 100%;
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

export const Form = styled.form`
    width: 100%;
    & > .StripeElement {
        margin-bottom: 32px;
    }
`;

export const Info = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #697386;
    text-align: center;
    margin-bottom: 8px;
`;

export const Cost = styled.span`
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #1a1f36;
    text-align: center;
    margin-bottom: 40px;
`;

export const Label = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #697386;
    margin-bottom: 8px;
`;

export const CloseButton = styled(Button)`
    position: absolute;
    right: 20px;
    top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8a26ee;
    padding: 10px;

    @media (max-width: 767px) {
        padding: 8px;
    }
`;

export const Group = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 32px;
    .StripeElement {
        width: 100%;
        border-radius: 8px;
        border: 1px solid rgba(60, 66, 87, 0.12);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.04);
        padding: 10px 12px;
    }
`;

export const NameInput = styled(Input)`
    width: 100%;
    padding: 10px 12px;
    margin: 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(60, 66, 87, 0.12);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.04);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1a1f36;
`;

export const stylesFormFieldSelect = (): StylesConfig => {
    return {
        ...stylesSelect(),
        control: (styles, state) => {
            return {
                ...styles,
                borderRadius: '8px',
                boxShadow: `0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04)`,
                border: `1px solid rgba(60, 66, 87, 0.12)`,
                backgroundColor: state.menuIsOpen ? '#FFFFFF' : '#FFFFFF',
                cursor: 'pointer',
                minHeight: '40px',
                height: 'min-content',
                transition: 'none',
                ':hover': {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid rgba(60, 66, 87, 0.12)`,
                },
                ':after': {
                    content: `''`,
                    width: '7px',
                    height: '7px',
                    position: 'absolute',
                    borderTop: '0.5px solid #14161A',
                    borderRight: '0.5px solid #14161A',
                    right: '10px',
                    top: state.menuIsOpen ? '-1px' : '-6px',
                    bottom: 0,
                    margin: '1.4em auto auto',
                    transform: state.menuIsOpen ? 'rotateZ(-45deg)' : 'rotateZ(135deg)',
                    cursor: 'pointer',
                },
            };
        },
        option: styles => ({
            ...styles,
            cursor: 'pointer',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
            color: '#1A1F36',
            padding: '7px',
            backgroundColor: '#ffffff',

            '&:active': {
                backgroundColor: '#ffffff',
            },
            ':hover': {
                backgroundColor: '#f5fafd',
                color: '#1A1F36',
            },
        }),
        singleValue: styles => ({
            ...styles,
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
            color: '#1A1F36',
            marginLeft: '0',
        }),
    };
};
