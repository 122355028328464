import React, { memo } from 'react';
import { VectorIcon } from 'shared/ui';
import { IAlertProps } from './Alert.types';
import { Container, CloseButton, Title, Description } from './Alert.style';

const AlertComponent: React.FC<IAlertProps> = ({ description, title, type, closeToast }) => {
    return (
        <Container type={type}>
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
            <CloseButton onClick={closeToast}>
                <VectorIcon icon="close-icon" size={[16, 16]} />
            </CloseButton>
        </Container>
    );
};

export const Alert = memo(AlertComponent);
