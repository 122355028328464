import React, { memo } from 'react';
import { FullScreenContainer, Container, Item, Description } from './BasicLoader.style';
import { IBasicLoaderProps } from './BasicLoader.types';

const BasicLoaderComponent: React.FC<IBasicLoaderProps> = ({ isCenter = false, isFullScreen }) => {
    return (
        <>
            {isFullScreen ? (
                <FullScreenContainer>
                    <Description>Loading...</Description>
                </FullScreenContainer>
            ) : (
                <Container isCenter={isCenter}>
                    <Item />
                    <Item />
                    <Item />
                </Container>
            )}
        </>
    );
};

export const BasicLoader = memo(BasicLoaderComponent);
