import styled from 'styled-components';

export const Container = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8a26ee;
    margin-bottom: 48px;
`;
