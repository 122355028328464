import React, { memo } from 'react';
import SelectBox from 'react-select';
import { Wrapper, stylesSelect, Label } from './Select.style';
import { ISelectProps } from './Select.types';

const SelectContainer: React.FC<ISelectProps> = ({ options, onChange, label, additionalStyles, ...other }) => {
    return (
        <Wrapper>
            {label && <Label>{label}</Label>}

            <SelectBox
                options={options}
                onChange={onChange}
                styles={additionalStyles ? additionalStyles() : stylesSelect()}
                {...other}
            />
        </Wrapper>
    );
};

export const Select = memo(SelectContainer);
