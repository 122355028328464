import React, { memo } from 'react';
import { VectorIcon } from 'shared/ui';
import { IMarkerProps, MarkerType } from './Marker.types';
import { Container } from './Marker.style';

const MarkerComponent: React.FC<IMarkerProps> = ({ status, markerSize, iconSize = [16, 16], icon = 'white-check' }) => {
    return (
        <>
            {status === MarkerType.COMPLETED ? (
                <Container markerSize={markerSize} status={status}>
                    <VectorIcon size={iconSize} icon={icon} />
                </Container>
            ) : (
                <Container markerSize={markerSize} status={status} />
            )}
        </>
    );
};

export const Marker = memo(MarkerComponent);
