import styled from 'styled-components';
import { StylesConfig } from 'react-select';

export const stylesSelect = (withCheckboxes?: boolean): StylesConfig => {
    return {
        control: (styles, state) => {
            return {
                ...styles,
                borderRadius: '8px',
                boxShadow: `none`,
                border: `none`,
                backgroundColor: state.menuIsOpen ? '#EDEFF2' : '#F6F6F6',
                cursor: 'pointer',
                minHeight: '40px',
                height: 'min-content',
                transition: 'none',
                ':hover': {
                    backgroundColor: '#EDEFF2',
                },
                ':after': {
                    content: `''`,
                    width: '7px',
                    height: '7px',
                    position: 'absolute',
                    borderTop: '0.5px solid #14161A',
                    borderRight: '0.5px solid #14161A',
                    right: '10px',
                    top: state.menuIsOpen ? '-1px' : '-6px',
                    bottom: 0,
                    margin: '1.4em auto auto',
                    transform: state.menuIsOpen ? 'rotateZ(-45deg)' : 'rotateZ(135deg)',
                    cursor: 'pointer',
                },
            };
        },
        input: styles => ({
            ...styles,
            margin: 0,
        }),
        indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
        }),
        placeholder: styles => ({
            ...styles,
            margin: '0',
            padding: '0',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16px',
            color: '#7E868D',
            opacity: '0.7',
        }),
        menu: styles => ({
            ...styles,
            animation: `0.25s forwards slideDown`,
            boxShadow:
                '0px 0px 2px rgba(83, 102, 137, 0.2), 0px 12px 16px rgba(83, 102, 137, 0.08), 0px 10px 40px rgba(83, 102, 137, 0.08)',
            borderRadius: '8px',
            border: 'none',
            margin: '8px 0 0',
            zIndex: '2',
        }),
        menuList: styles => ({
            ...styles,
            borderRadius: '8px',
            maxHeight: '208px',
            padding: '8px',
        }),
        option: styles => ({
            ...styles,
            cursor: 'pointer',
            position: 'relative',
            color: `#121217`,
            fontSize: '14px',
            lineHeight: '16px',
            padding: '8px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            '&:active': {
                backgroundColor: '#ffffff',
            },
            ':hover': {
                backgroundColor: '#F6F6F6',
                color: `#121217`,
            },
        }),
        indicatorsContainer: styles => ({
            ...styles,
            display: 'none',
        }),
        valueContainer: styles => ({
            ...styles,
            margin: '0',
            padding: '0 12px',
        }),
        singleValue: styles => ({
            ...styles,
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16px',
            color: '#121217',
            paddingRight: '10px',
        }),
        multiValue: styles => ({
            ...styles,
            borderRadius: '4px',
            padding: '2px',
            background: '#f2f2f2',
            justifyContent: 'center',
            alignItems: 'center',
        }),

        multiValueLabel: styles => ({
            ...styles,
            padding: '2px 4px 2px 2px',
            paddingLeft: '2px',
            fontSize: '10.5px',
            fontWeight: '400',
            color: '#303030;',
        }),
        multiValueRemove: styles => ({
            ...styles,
            padding: '0',
            borderRadius: '16px',
            width: '16px',
            height: '16px',
            background: 'rgba(10, 10, 10, 0.2)',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                color: '#fff',
            },
        }),
        group: styles => ({
            ...styles,
            padding: '0',
        }),
        groupHeading: styles => ({
            ...styles,
            fontWeight: '500',
            fontSize: '11px',
            color: '#7a7a7a',
            borderBottom: '1px solid #dbdbdb',
            backgroundColor: '#f5f5f5',
            cursor: 'default',
            padding: '5px 10px',
            margin: '0',
        }),
    };
};

export const Wrapper = styled.div`
    width: 100%;
`;

export const Label = styled.label`
    display: inline-block;
    color: #363636;
    font-size: 14px;
    margin-bottom: 8px;
`;
