import styled, { css } from 'styled-components';
import { ButtonColorType, ButtonFormType, IButtonStylesProps } from './Button.types';

export const Container = styled.button<IButtonStylesProps>`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width ?? 'max-content'};
    height: ${({ height }) => height ?? 'max-content'};
    outline: transparent;
    transition: all 0.3s;
    cursor: pointer;
    padding: 10px 20px;
    ${({ formType }) =>
        formType === ButtonFormType.ROUND &&
        css`
            border-radius: 100%;
            padding: 0;
        `}
    ${({ colorType }) =>
        (colorType === ButtonColorType.BLUE &&
            css`
                color: #ffffff;
                background: #1268fb;
                &:hover {
                  background: #1060EA;
                }
            `) ||
        (colorType === ButtonColorType.WHITE &&
            css`
                border: 2px solid #dee1e5;
                color: #14161a;
                background: transparent;
                padding: 10px;
            `) ||
        (colorType === ButtonColorType.LIGHT_BLUE &&
            css`
                color: #1268fb;
                background: rgba(18, 104, 251, 0.1);
                &:hover {
                  background: rgba(18, 104, 251, 0.2);
                }
            `) ||
        (colorType === ButtonColorType.BASE &&
            css`
                background: transparent;
                padding: 10px;
            `) ||
        (colorType === ButtonColorType.GREEN &&
            css`
                background: rgba(0, 172, 110, 0.1);
                color: #068a5a;
            `)}
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.4;
        `}
`;
