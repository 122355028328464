import React, { memo } from 'react';
import { ModalInterface } from './Modal.types';
import { Fade, Container, Background, Content } from './Modal.style';

const ModalContainer: React.FC<ModalInterface> = props => {
    const { children, onClose, isActive, isDanger } = props;

    return (
        <Fade isActive={isActive}>
            <Container isActive={isActive} isDanger={isDanger}>
                <Background onClick={onClose} />
                <Content>{children}</Content>
            </Container>
        </Fade>
    );
};

export const Modal = memo(ModalContainer);
