import styled from 'styled-components';
import { Link as LinkRouterDom } from 'react-router-dom';

export const Container = styled.footer`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 35px 15px;
    background-color: #fff;
    color: #4a4a4a;
`;

export const Navigation = styled.nav`
    margin: 0 auto;
`;

export const NavList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 415px) {
        flex-direction: column;
    }
`;

export const NavItem = styled.li`
    margin: 0 24px 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 415px) {
        margin: 0 0 24px 0;
        &:last-child {
            margin: 0;
        }
    }
`;

export const Link = styled(LinkRouterDom)`
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7e868d;
    text-decoration: none;
`;

export const Copyright = styled.p`
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7e868d;
`;
