import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link as LinkReactRouterDom } from 'react-router-dom';

export const Container = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    padding: 20px 30px;
    border-bottom: 1px solid #edeff2;
    background-color: #ffffff;
    color: #7e868d;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    @media (max-width: 767px) {
        height: 52px;
        padding: 15px 10px;
    }
`;

export const Image = styled.img`
    width: 100%;
`;

export const HeaderLogo = styled(LinkReactRouterDom)`
    width: 127px;
`;

export const LinkHeaderLogo = styled(HashLink)`
    width: 127px;
    text-decoration: none;
`;

export const BrandLogo = styled.figure`
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Image} {
        max-width: 94px;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const Description = styled.figcaption`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7e868d;
    margin-right: 8px;
`;

export const HeaderNavigation = styled.nav``;

export const NavList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NavItem = styled.li`
    margin: 0 48px 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
        margin-right: 0;
    }
`;

export const Link = styled(HashLink)`
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7e868d;
    text-decoration: none;
`;
