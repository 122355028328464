import React from 'react';
import { Title } from 'shared/ui';

const ErrorPage: React.FC = () => {
    return (
        <>
            <Title text="404 ErrorPage" />
        </>
    );
};

export default ErrorPage;
