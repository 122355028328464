import styled, { css } from 'styled-components';
import { IBasicLoaderProps } from './BasicLoader.types';

export const Container = styled.div<IBasicLoaderProps>`
    display: flex;
    align-items: center;
    ${({ isCenter }) =>
        isCenter &&
        css`
            justify-content: center;
        `}
`;

export const FullScreenContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    user-select: none;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    will-change: opacity;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    z-index: 2147483004;
`;

export const Description = styled.p`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: normal;
    color: #7e868d;
    margin-top: 15px;
`;

export const Item = styled.span`
    animation: basic-loader 0.6s ease-in-out infinite alternate;
    backface-visibility: hidden;
    background-clip: padding-box;
    background-color: #b5b5b5;
    border-radius: 50%;
    display: block;
    height: 6px;
    transform: scale(0.2);
    width: 6px;
    color: #4a4a4a;

    &:not(:last-child) {
        margin-right: 3px;
    }

    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes basic-loader {
        to {
            transform: scale(1);
        }
    }
`;
