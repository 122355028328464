import React, { memo } from 'react';
import { IVectorIconProps, IconTypes } from './VectorIcon.types';
import { Container } from './VectorIcon.style';

const icons = (props: IVectorIconProps = {}): IconTypes => {
    const { classes = '' } = props;

    return {
        cellphone: `
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <path d="M33.88 4.6875C33.88 2.10266 31.7773 0 29.1925 0H10.8594C8.27454 0 6.17188 2.10266 6.17188 4.6875V35.3125C6.17188 37.8973 8.27454 40 10.8594 40H29.1928C31.7773 40 33.8803 37.8973 33.8803 35.3125V4.6875H33.88ZM9.29688 9.27063H30.7553V30.7291L9.29688 30.7285V9.27063ZM10.8594 3.125H29.1928C30.0543 3.125 30.7553 3.82599 30.7553 4.6875V6.14563H9.29688V4.6875C9.29688 3.82599 9.99786 3.125 10.8594 3.125ZM29.1925 36.875H10.8594C9.99786 36.875 9.29688 36.174 9.29688 35.3125V33.8535L30.7553 33.8541V35.3125C30.755 36.174 30.0543 36.875 29.1925 36.875Z" fill="#1268FB"/>
            </svg>
        `,
        telephone: `
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <g clip-path="url(#clip0_855_21651)">
                    <path d="M33.9114 17.9233C33.6526 17.3785 33.1033 17.0312 32.5 17.0312H25.8594V14.2188C25.8594 13.3557 25.1599 12.6562 24.2969 12.6562C23.4338 12.6562 22.7344 13.3557 22.7344 14.2188V17.0312H17.2656V14.2188C17.2656 13.3557 16.5662 12.6562 15.7031 12.6562C14.8401 12.6562 14.1406 13.3557 14.1406 14.2188V17.0312H7.5C6.89667 17.0312 6.34735 17.3785 6.08856 17.9233L0.151062 30.4233C0.0515747 30.6329 0 30.8618 0 31.0938V37.6562C0 38.5193 0.699463 39.2188 1.5625 39.2188H38.4375C39.3005 39.2188 40 38.5193 40 37.6562V31.0938C40 30.8618 39.9484 30.6329 39.8489 30.4233L33.9114 17.9233ZM36.875 36.0938H3.125V31.4459L8.48755 20.1562H31.5125L36.875 31.4459V36.0938ZM32.3438 0.78125H7.65625C3.43475 0.78125 0 4.216 0 8.4375V14.5312C0 15.3943 0.699463 16.0938 1.5625 16.0938H9.84375C10.7068 16.0938 11.4062 15.3943 11.4062 14.5312V11.4062H28.5938V14.5312C28.5938 15.3943 29.2932 16.0938 30.1562 16.0938H38.4375C39.3005 16.0938 40 15.3943 40 14.5312V8.4375C40 4.216 36.5652 0.78125 32.3438 0.78125ZM36.875 12.9688H31.7188V9.84375C31.7188 8.98071 31.0193 8.28125 30.1562 8.28125H9.84375C8.98071 8.28125 8.28125 8.98071 8.28125 9.84375V12.9688H3.125V8.4375C3.125 5.93903 5.15778 3.90625 7.65625 3.90625H32.3438C34.8422 3.90625 36.875 5.93903 36.875 8.4375V12.9688ZM20 22.5C16.8985 22.5 14.375 25.0235 14.375 28.125C14.375 31.2265 16.8985 33.75 20 33.75C23.1015 33.75 25.625 31.2265 25.625 28.125C25.625 25.0235 23.1015 22.5 20 22.5ZM20 30.625C18.6215 30.625 17.5 29.5035 17.5 28.125C17.5 26.7465 18.6215 25.625 20 25.625C21.3785 25.625 22.5 26.7465 22.5 28.125C22.5 29.5035 21.3785 30.625 20 30.625Z" fill="#09AAEF"/>
                </g>
                <defs>
                    <clipPath id="clip0_855_21651">
                        <rect width="40" height="40" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        `,
        bitphone: `
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <g clip-path="url(#clip0_855_21645)">
                <path d="M40 20.625C40 16.3309 36.7456 12.7829 32.5735 12.3172C32.3935 5.49469 26.7874 0 19.9219 0C16.7694 0 13.7488 1.16638 11.4166 3.284C9.40674 5.10864 8.04901 7.50763 7.51709 10.1355C5.61737 10.5441 3.88306 11.5268 2.5415 12.9733C0.90271 14.7403 0 17.0416 0 19.4531C0 23.9838 3.1781 27.785 7.42218 28.7476C8.12256 35.0681 13.4958 40 20 40C26.4401 40 31.7722 35.1648 32.5568 28.934C36.7368 28.4763 40 24.9249 40 20.625ZM20 36.875C14.7446 36.875 10.4688 32.5992 10.4688 27.3438C10.4688 22.0883 14.7446 17.8125 20 17.8125C25.2554 17.8125 29.5312 22.0883 29.5312 27.3438C29.5312 32.5992 25.2554 36.875 20 36.875ZM32.5592 25.7764C31.785 19.5346 26.4481 14.6875 20 14.6875C13.6417 14.6875 8.36395 19.4009 7.47589 25.5173C4.94934 24.6588 3.125 22.2665 3.125 19.4531C3.125 16.1368 5.71075 13.3322 9.01154 13.0679L10.3082 12.9642L10.441 11.6699C10.9409 6.7984 15.0168 3.125 19.9219 3.125C25.1773 3.125 29.4531 7.40082 29.4531 12.6562V15.3906H31.6406C34.527 15.3906 36.875 17.7386 36.875 20.625C36.875 23.1976 35.0085 25.3406 32.5592 25.7764Z" fill="#8A26EE"/>
                <g clip-path="url(#clip1_855_21645)">
                <path d="M22.1966 32.5C20.4436 32.5 18.5715 31.8558 17.1078 30.3922C15.6465 28.9309 15 27.0601 15 25.3034C15 23.7552 16.2526 22.5 17.8034 22.5C17.9232 22.5 18.0309 22.5729 18.0754 22.6842L19.3306 25.8222C19.3907 25.9724 19.3176 26.1429 19.1674 26.203L17.7886 26.7545C17.8861 28.3408 19.1594 29.6141 20.7455 29.7115L21.297 28.3326C21.357 28.1826 21.5274 28.1093 21.6778 28.1694L24.8158 29.4246C24.9271 29.4691 25 29.5768 25 29.6966C25 31.2448 23.7474 32.5 22.1966 32.5V32.5Z" fill="#8A26EE"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_855_21645">
                <rect width="40" height="40" fill="white"/>
                </clipPath>
                <clipPath id="clip1_855_21645">
                <rect width="10" height="10" fill="white" transform="translate(15 22.5)"/>
                </clipPath>
                </defs>
            </svg>
        `,
        'identify-phone': `
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <rect width="56" height="56" rx="12" fill="#1268FB" fill-opacity="0.08"/>
                <path d="M35.0429 31.5647C34.5551 31.0768 33.7631 31.0765 33.2749 31.5647L31.5071 33.3326C31.0197 33.82 30.2265 33.82 29.7391 33.3326L22.6674 26.2609C22.18 25.7735 22.18 24.9803 22.6674 24.4929L24.4354 22.725C24.9232 22.2371 24.9235 21.4452 24.4354 20.9571L19.1316 15.6533C18.6433 15.1651 17.8518 15.1651 17.3637 15.6533L13.8278 19.1891C11.3907 21.6262 11.3907 25.5916 13.8278 28.0287L27.9712 42.1722C30.4082 44.6093 34.3737 44.6094 36.8108 42.1722L40.3467 38.6364C40.8349 38.1482 40.8349 37.3567 40.3467 36.8685L35.0429 31.5647ZM35.0429 40.4043C33.5806 41.8666 31.2014 41.8666 29.7391 40.4043L15.5957 26.2609C14.1334 24.7986 14.1334 22.4193 15.5957 20.9571L18.2475 18.3052L21.7834 21.841L20.8994 22.725C19.4372 24.1873 19.4372 26.5665 20.8994 28.0288L27.9711 35.1006C29.4335 36.5628 31.8127 36.5628 33.275 35.1005L34.1589 34.2166L37.6948 37.7525L35.0429 40.4043Z" fill="#1268FB"/>
                <path d="M35.2493 12C30.4241 12 26.4985 15.9256 26.4985 20.7508C26.4985 25.576 30.4241 29.5016 35.2493 29.5016C40.0745 29.5016 44.0002 25.576 44.0002 20.7508C44.0002 15.9256 40.0745 12 35.2493 12ZM35.2493 27.0014C31.8028 27.0014 28.9988 24.1974 28.9988 20.7508C28.9988 17.3042 31.8028 14.5002 35.2493 14.5002C38.6959 14.5002 41.4999 17.3042 41.4999 20.7508C41.4999 24.1974 38.6959 27.0014 35.2493 27.0014Z" fill="#1268FB"/>
                <path d="M35.9317 22.7871H34.3847C34.3161 22.7871 34.2559 22.813 34.2043 22.8645C34.1527 22.9161 34.127 22.9762 34.127 23.045V24.592C34.127 24.6607 34.1528 24.7209 34.2043 24.7724C34.2559 24.8239 34.316 24.8498 34.3847 24.8498H35.9317C36.0003 24.8498 36.0607 24.824 36.112 24.7724C36.1637 24.7209 36.1895 24.6607 36.1895 24.592V23.045C36.1895 22.9762 36.1638 22.9161 36.112 22.8645C36.0607 22.8129 36.0003 22.7871 35.9317 22.7871Z" fill="#1268FB"/>
                <path d="M37.962 18.1562C37.7858 17.8316 37.5581 17.5578 37.2787 17.3343C36.9995 17.1109 36.6856 16.9325 36.3377 16.7994C35.9896 16.6663 35.6437 16.5996 35.2999 16.5996C33.9937 16.5996 32.9969 17.1711 32.3093 18.3141C32.275 18.37 32.2631 18.4301 32.2738 18.4946C32.2846 18.559 32.3179 18.6127 32.3738 18.6556L33.4309 19.4614C33.4867 19.4957 33.5404 19.5129 33.592 19.5129C33.6694 19.5129 33.7359 19.4784 33.7919 19.4097C34.1012 19.023 34.3311 18.776 34.4815 18.6685C34.6663 18.5439 34.8983 18.4817 35.1776 18.4817C35.4397 18.4817 35.6706 18.5504 35.8704 18.6878C36.0702 18.8253 36.17 18.9843 36.17 19.1648C36.17 19.3667 36.1164 19.53 36.009 19.6546C35.9016 19.7792 35.721 19.8995 35.4675 20.0155C35.1324 20.1659 34.825 20.399 34.5459 20.7149C34.2665 21.0307 34.1269 21.367 34.1269 21.7236V22.0136C34.1269 22.0953 34.1494 22.1759 34.1946 22.2554C34.2397 22.3349 34.2945 22.3746 34.3589 22.3746H35.9058C35.9746 22.3746 36.0347 22.3414 36.0863 22.2746C36.1378 22.2082 36.1636 22.1383 36.1636 22.0654C36.1636 21.958 36.2227 21.8139 36.3408 21.6335C36.459 21.453 36.6061 21.3132 36.7824 21.2144C36.9499 21.1199 37.0808 21.0437 37.1755 20.9856C37.2702 20.9277 37.3936 20.834 37.5462 20.7052C37.6988 20.5763 37.8169 20.4484 37.9006 20.3217C37.9844 20.195 38.0597 20.0306 38.1263 19.8286C38.193 19.6266 38.2262 19.4096 38.2262 19.1776C38.2262 18.821 38.138 18.4805 37.962 18.1562Z" fill="#1268FB"/>
            </svg>
        `,
        'validate-numbers': `
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <rect width="56" height="56" rx="12" fill="#E7F5FB"/>
                <g clip-path="url(#clip0_855_21608)">
                <path d="M42.75 12H13.25C12.5596 12 12 12.5596 12 13.25V42.75C12 43.4404 12.5596 44 13.25 44H42.75C43.4404 44 44 43.4404 44 42.75V13.25C44 12.5596 43.4404 12 42.75 12ZM41.5 41.5H14.5V14.5H41.5V41.5ZM17.1162 20.5742C16.6279 20.0862 16.6279 19.2947 17.1162 18.8064C17.6042 18.3184 18.3958 18.3184 18.8838 18.8064L19.6931 19.6157L22.3662 16.9429C22.8542 16.4546 23.6458 16.4546 24.1338 16.9429C24.6221 17.4312 24.6221 18.2224 24.1338 18.7107L20.5771 22.2673C20.0889 22.7556 19.2974 22.7554 18.8093 22.2673L17.1162 20.5742ZM26.75 19.6052C26.75 18.9148 27.3096 18.3552 28 18.3552H38C38.6904 18.3552 39.25 18.9148 39.25 19.6052C39.25 20.2954 38.6904 20.8552 38 20.8552H28C27.3096 20.8552 26.75 20.2954 26.75 19.6052ZM17.1162 37.364C16.6279 36.8757 16.6279 36.0842 17.1162 35.5962C17.6042 35.1082 18.3958 35.1079 18.8838 35.5962L19.6931 36.4055L22.3662 33.7327C22.8542 33.2444 23.6458 33.2444 24.1338 33.7327C24.6221 34.2207 24.6221 35.0122 24.1338 35.5005L20.5771 39.0571C20.0889 39.5454 19.2974 39.5452 18.8093 39.0571L17.1162 37.364ZM26.75 36.3948C26.75 35.7046 27.3096 35.1448 28 35.1448H38C38.6904 35.1448 39.25 35.7046 39.25 36.3948C39.25 37.0852 38.6904 37.6448 38 37.6448H28C27.3096 37.6448 26.75 37.0852 26.75 36.3948ZM17.1162 28.9692C16.6279 28.481 16.6279 27.6895 17.1162 27.2014C17.6042 26.7131 18.3958 26.7131 18.8838 27.2014L19.6931 28.0107L22.3662 25.3376C22.8542 24.8496 23.6458 24.8496 24.1338 25.3376C24.6221 25.8259 24.6221 26.6174 24.1338 27.1055L20.5771 30.6624C20.0889 31.1504 19.2974 31.1504 18.8093 30.6624L17.1162 28.9692ZM26.75 28C26.75 27.3096 27.3096 26.75 28 26.75H38C38.6904 26.75 39.25 27.3096 39.25 28C39.25 28.6904 38.6904 29.25 38 29.25H28C27.3096 29.25 26.75 28.6904 26.75 28Z" fill="#09AAEF"/>
                </g>
                <defs>
                <clipPath id="clip0_855_21608">
                <rect width="32" height="32" fill="white" transform="translate(12 12)"/>
                </clipPath>
                </defs>
            </svg>
        `,
        'improve-message': `
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <rect width="56" height="56" rx="12" fill="#8A26EE" fill-opacity="0.1"/>
                <path d="M43.9999 18.0023C43.9999 17.283 43.3974 16.7354 42.7477 16.7503H17.9999C16.8874 16.7503 16.328 18.1031 17.12 18.888L26.2206 27.9097L17.111 37.1214C16.3319 37.9092 16.892 39.2503 17.9999 39.2503H42.7499C43.4342 39.2503 43.9979 38.692 43.9996 38.0037C43.9996 38.0025 43.9999 38.0015 43.9999 38.0003V18.0023ZM41.4999 34.9586L34.5292 27.9097L41.4999 20.9996V34.9586ZM21.0365 19.2503H39.7133L30.3749 28.5076L21.0365 19.2503ZM27.996 29.6697L29.4948 31.1556C29.9821 31.6385 30.7675 31.6385 31.2548 31.1556L32.7536 29.6697L39.7558 36.7503H20.994L27.996 29.6697Z" fill="#8A26EE"/>
                <path d="M14.8333 24.5H18C18.6904 24.5 19.25 23.9404 19.25 23.25C19.25 22.5596 18.6904 22 18 22H14.8333C14.1428 22 13.5833 22.5596 13.5833 23.25C13.5833 23.9404 14.1431 24.5 14.8333 24.5Z" fill="#8A26EE"/>
                <path d="M17.6667 28C17.6667 27.3096 17.1072 26.75 16.4167 26.75H13.25C12.5596 26.75 12 27.3096 12 28C12 28.6904 12.5596 29.25 13.25 29.25H16.4167C17.1069 29.25 17.6667 28.6904 17.6667 28Z" fill="#8A26EE"/>
                <path d="M19.25 32.75C19.25 32.0596 18.6904 31.5 18 31.5H14.8333C14.1428 31.5 13.5833 32.0596 13.5833 32.75C13.5833 33.4404 14.1428 34 14.8333 34H18C18.6904 34 19.25 33.4404 19.25 32.75Z" fill="#8A26EE"/>
            </svg>
        `,
        minus: `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <g clip-path="url(#clip0_853_15813)">
                <mask id="mask0_853_15813" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="6" width="12" height="3">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99991 8.00056C1.9996 7.44828 2.44706 7.00031 2.99935 7L12.9979 6.99438C13.5502 6.99407 13.9981 7.44154 13.9984 7.99382C13.9988 8.54611 13.5513 8.99407 12.999 8.99438L3.00047 9C2.44818 9.00031 2.00022 8.55285 1.99991 8.00056Z" fill="#14161A"/>
                </mask>
                <g mask="url(#mask0_853_15813)">
                <rect width="16" height="16" fill="#7E868D"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_853_15813">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        `,
        plus: `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <g clip-path="url(#clip0_853_15800)">
                <mask id="mask0_853_15800" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="12">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99989 3.00016C8.99997 2.44788 8.55232 2.0001 8.00004 2.00001C7.44775 1.99993 6.99997 2.44758 6.99989 2.99987L6.99931 6.99777L2.99936 7.00001C2.44708 7.00032 1.99961 7.44829 1.99992 8.00058C2.00023 8.55286 2.4482 9.00032 3.00048 9.00001L6.99901 8.99777L6.99843 12.9943C6.99835 13.5465 7.446 13.9943 7.99828 13.9944C8.55056 13.9945 8.99835 13.5468 8.99843 12.9945L8.99901 8.99665L12.999 8.9944C13.5513 8.99409 13.9988 8.54612 13.9985 7.99384C13.9981 7.44155 13.5502 6.99409 12.9979 6.9944L8.99931 6.99664L8.99989 3.00016Z" fill="#14161A"/>
                </mask>
                <g mask="url(#mask0_853_15800)">
                <rect width="16" height="16" fill="#7E868D"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_853_15800">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        `,
        'white-check': `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_853_27663" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="11">
                <path d="M2 8.4L5.42857 12L14 3" stroke="#14161A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </mask>
                <g mask="url(#mask0_853_27663)">
                <rect width="16" height="16" fill="white"/>
                </g>
            </svg>
        `,
        'white-check-small': `
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_853_31251" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="7">
                <path d="M1 4.2L2.71429 6L7 1.5" stroke="#14161A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </mask>
                <g mask="url(#mask0_853_31251)">
                <rect width="8" height="8" fill="white"/>
                </g>
            </svg>
        `,
        add: `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <mask id="mask0_853_22258" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="26">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4983 4.67847C17.4983 3.85004 16.8267 3.17847 15.9983 3.17847C15.1698 3.17847 14.4983 3.85004 14.4983 4.67847L14.4983 14.4944L4.68236 14.4944C3.85393 14.4944 3.18236 15.166 3.18236 15.9944C3.18236 16.8228 3.85393 17.4944 4.68236 17.4944L14.4983 17.4944L14.4983 27.3103C14.4983 28.1387 15.1698 28.8103 15.9983 28.8103C16.8267 28.8103 17.4983 28.1387 17.4983 27.3103L17.4983 17.4944H27.3142C28.1426 17.4944 28.8142 16.8228 28.8142 15.9944C28.8142 15.166 28.1426 14.4944 27.3142 14.4944H17.4983L17.4983 4.67847Z" fill="#14161A"/>
                </mask>
                <g mask="url(#mask0_853_22258)">
                <rect width="32" height="32" fill="white"/>
                </g>
            </svg>
        `,
        'load-arrow': `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <mask id="mask0_853_25070" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="22" height="28">
                <path d="M16.0001 4.802L16.0001 27.602" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 12.4021L16 4.4021L25 12.4021" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </mask>
                <g mask="url(#mask0_853_25070)">
                <rect width="32" height="32" fill="white"/>
                </g>
            </svg>
        `,
        'close-icon': `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <g clip-path="url(#clip0_853_23678)">
                    <mask id="mask0_853_23678" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="13" height="13">
                        <path d="M15 4.99561L4.99805 14.9976" stroke="#14161A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.99805 4.99561L15 14.9976" stroke="#14161A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </mask>
                    <g mask="url(#mask0_853_23678)">
                        <rect width="20" height="20" fill="#7E868D"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_853_23678">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        `,
        'check-success': `
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" class='${classes}'>
                <mask id="mask0_853_28374" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="11" y="18" width="98" height="76">
                <path d="M15 63L40.7143 90L105 22.5" stroke="#14161A" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                </mask>
                <g mask="url(#mask0_853_28374)">
                <rect width="120" height="120" fill="#00AC6E"/>
                </g>
            </svg>
        `,
        'completed-logo': `
            <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.493 3.95595L27.977 12H21.921C21.133 12 20.493 11.238 20.493 10.3V3.95595ZM31.9783 10.6561L23.0882 0.656004C22.7102 0.238001 22.1722 0 21.6082 0H5.60812C2.78811 0 0.496094 2.24401 0.496094 5.00003V35.0002C0.496094 37.7562 2.78811 40.0002 5.60812 40.0002H27.3843C30.2043 40.0002 32.4963 37.7562 32.4963 35.0002V12.0001C32.4963 11.5021 32.3123 11.0241 31.9783 10.6561Z" fill="#00AC6E"/>
                <mask id="mask0_882_18713" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="16" width="13" height="11">
                <path d="M11.0938 22.3603L14.1803 25.6012L21.8968 17.4989" stroke="#14161A" stroke-width="1.80051" stroke-linecap="round" stroke-linejoin="round"/>
                </mask>
                <g mask="url(#mask0_882_18713)">
                <rect x="9.29688" y="14.7981" width="14.4041" height="14.4041" fill="white"/>
                </g>
            </svg>
        `,
        'play-icon': `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16ZM6.58857 4.65829C6.41242 4.54819 6.19039 4.54237 6.00871 4.64306C5.82702 4.74376 5.71429 4.93513 5.71429 5.14286V10.8571C5.71429 11.0649 5.82702 11.2562 6.00871 11.3569C6.19039 11.4576 6.41242 11.4518 6.58857 11.3417L11.16 8.48457C11.3271 8.38015 11.4286 8.19702 11.4286 8C11.4286 7.80298 11.3271 7.61985 11.16 7.51543L6.58857 4.65829Z" fill="#1268FB"/>
            </svg>
        `,
    };
};

const VectorIconComponent: React.FC<IVectorIconProps> = props => {
    const { size, fill, clearMargin = '', padding = '', icon = '' } = props;

    const styles = function () {
        if (Array.isArray(size)) {
            return {
                width: `${size[0]}px`,
                height: `${size[1]}px`,
                ...(fill ? { fill: fill } : null),
                ...(clearMargin ? { marginRight: '0px' } : null),
                ...(padding ? { padding: `${padding}px` } : null),
            };
        }

        return {
            width: `${size}px`,
            height: `${size}px`,
            fill: `${fill}`,
            ...(fill ? { fill: fill } : null),
            ...(clearMargin ? { marginRight: '0px' } : null),
            ...(padding ? { padding: `${padding}px` } : null),
        };
    };

    const getIcon = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return icons(props)[icon] || '';
    };

    return <Container dangerouslySetInnerHTML={{ __html: getIcon() }} style={styles()} />;
};

export const VectorIcon = memo(VectorIconComponent);
