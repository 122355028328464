import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise: Stripe | Promise<Stripe | null>;

const getStripe = (key: string) => {
    if (!stripePromise && key) {
        stripePromise = loadStripe(key, { locale: 'en' });
    }

    return stripePromise;
};

export default getStripe;
