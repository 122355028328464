import { ValueOf } from 'shared/types/general';

export interface IReportProps {
    type: ReportType;
}

export const ReportType = {
    BASIC: 'Basic',
    FULL: 'Full',
} as const;

type ReportType = ValueOf<typeof ReportType>;
