export const TABLET_WIDTH = 768;
export const MOBILE_WIDTH = 415;

export const ENV_DEVELOPMENT = 'development';
export const ENV_PRODUCTION = 'production';

export const LIVE_ACCOUNT_PORTAL_ID = '3922341';
export const MIGRATION_TEST_ACCOUNT_PORTAL_ID = '6024140';
export const LIVE_ACCOUNT_FORM_GUID = 'f0f3d496-0767-4271-abe6-1872ee125e97';
export const MIGRATION_TEST_ACCOUNT_FORM_GUID = '00779ed3-46c0-437c-a014-63d8c6f9651e';

export const POSTHOG_HOST = 'https://app.posthog.com';
export const POSTHOG_KEY = 'phc_mUFUE2AxnpFJTYRz9PRF663xSDTpP09W7yvtBM10OE4';
