import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'shared/consts/paths';

interface IScrollToTopProps {
    children?: React.ReactNode;
}

const ScrollToTop: React.FC<IScrollToTopProps> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        if (!(location.pathname === PATHS.HOME && location.hash.length)) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{children}</>;
};

export default ScrollToTop;
