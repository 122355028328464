import styled from 'styled-components';
import { Container as ReportComponentContainer } from 'entities/Upload/ui/Report/Report.style';

export const Container = styled.div`
    width: 100%;
    max-width: 808px;
`;

export const HubspotFormContainer = styled.div`
    display: none;
`;

export const ReportContainer = styled.div`
    width: 100%;
    max-width: 808px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${ReportComponentContainer} {
        margin-right: 10px;
        &:last-child {
            margin-left: 10px;
            margin-right: 0;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        ${ReportComponentContainer} {
            margin: 0 auto 20px;
            &:last-child {
                margin: 0 auto 20px;
            }
        }
    }
`;

export const Column = styled.span`
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 415px) {
        width: 45%;
        &:nth-child(1),
        &:nth-child(3) {
            margin-bottom: 50px;
        }
    }
`;

export const Separator = styled.span`
    width: 2px;
    height: 16px;
    background: #dee1e5;
    border-radius: 2px;

    @media (max-width: 415px) {
        &:nth-child(4) {
            display: none;
        }
    }
`;

export const Count = styled.span`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #121217;
    text-align: center;
    margin-bottom: 8px;
`;

export const Name = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #7e868d;
`;

export const InfoTable = styled.div`
    width: 100%;
    background: #ffffff;
    border: 1px solid #edeff2;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 75px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      padding: 30px;
    }

    @media (max-width: 415px) {
        flex-wrap: wrap;
    }
`;

export const Description = styled.article`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;

    @media (max-width: 415px) {
        margin-bottom: 30px;
    }
`;
