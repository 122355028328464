import styled, { css } from 'styled-components';
import { IUploaderDropzoneStylesProps } from './UploaderDropzone.types';

export const Container = styled.div<IUploaderDropzoneStylesProps>`
    width: 100%;
    max-width: 808px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    border: 2px solid #edeff2;
    :hover {
        border: 2px solid rgba(18, 104, 251, 0.2);
        box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
        cursor: pointer;

        button {
          background: #1060EA;
        }
    }

    ${({ isShowInformation }) =>
        isShowInformation &&
        css`
            max-width: 900px;
            padding: 76px 20px 104px;
        `}

    ${({ isActive }) =>
        isActive &&
        css`
            border: 2px solid rgba(18, 104, 251, 0.2);
            box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
        `}
`;

export const InfoContainer = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ActionContainer = styled.div`
    padding: 28px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #121217;
    margin-bottom: 8px;

  @media (max-width: 380px) {
      max-width: 225px;
`;

export const Description = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #7e868d;
`;
