import { useCallback, useEffect, useState } from 'react';
import useWindowResizeObserver from 'shared/lib/hooks/useWindowResizeObserver';

const useGetTemplateStyles = () => {
    const [sizeH1, setSizeH1] = useState<string>('');
    const [sizeH2, setSizeH2] = useState<string>('');
    const [sizeSubTitle, setSizeSubTitle] = useState<string>('');
    const [marginH2, setMarginH2] = useState<string>('');
    const { width } = useWindowResizeObserver();

    const updateParams = useCallback(() => {
        if (width < 768) {
            setSizeH1('38px');
            setSizeH2('26px');
            setSizeSubTitle('18px');
            setMarginH2('32px 0 16px');
        } else {
            setSizeH1('40px');
            setSizeH2('32px');
            setSizeSubTitle('20px');
            setMarginH2('40px 0 20px');
        }
    }, [width]);

    useEffect(() => {
        updateParams();
    }, [width]);

    return {
        sizeH1,
        sizeH2,
        sizeSubTitle,
        marginH2,
    };
};

export default useGetTemplateStyles;
