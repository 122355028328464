import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { PATHS } from 'shared/consts/paths';
import { sessionInfo } from 'entities/Upload/store/upload/reducers/ActionCreator';
import Layout from 'entities/Upload/ui/Layout/Layout';
import ThankSection from 'entities/Upload/ui/ThankSection/ThankSection';

const CouponPage = () => {
    const { token } = useAppSelector(state => state.uploadReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token) {
            dispatch(sessionInfo(token));
        } else {
            navigate(PATHS.HOME);
        }
    }, []);

    return (
        <Layout>
            <ThankSection />
        </Layout>
    );
};

export default CouponPage;
