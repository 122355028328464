import styled from 'styled-components';
import { IColumn } from './PricingTable.types';

export const BTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
`;

export const BTableBody = styled.tbody`
    padding: 10px;
`;

export const BTableTd = styled.td<IColumn>`
    background: #fbfbfb;
    border-spacing: 0;
    padding: 22px 24px;
    width: ${({ width }) => width};
    @media (max-width: 415px) {
        padding: 20px 24px;
    }
`;

export const BTableTr = styled.tr`
    ${BTableTd} {
        &:last-child {
            border-bottom-right-radius: 12px;
            border-top-right-radius: 12px;
        }
        &:first-child {
            border-bottom-left-radius: 12px;
            border-top-left-radius: 12px;
        }
    }
    &:nth-child(2n) {
        ${BTableTd} {
            background: transparent;
        }
    }
`;

export const BTableTh = styled.th<IColumn>`
    text-align: ${({ textAlign }) => textAlign};
    @media (max-width: 415px) {
        vertical-align: baseline;
    }
`;

export const BTableHead = styled.thead`
    ${BTableTr} {
        background: #ffffff;
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;
