export const PATHS = {
    HOME: '/',
    HOME_HEAD: '/#main',
    HOME_UPLOAD: '/#upload',
    HOME_HOW_IT_WORK: '/#section-how-it-work',
    HOME_FAQS: '/#section-faqs',
    UPLOAD: '/upload',
    TERMS_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    DOWNLOAD: '/download/:id',
    CHECK: '/check',
    REPORT: '/report',
    COUPON: '/coupon',
    ERROR_PAGE: '*',
};
