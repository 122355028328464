import React from 'react';
import { SubTitle, Title } from 'shared/ui';
import { TextAlignType } from 'shared/types/general';
import useGetTemplateStyles from 'shared/lib/hooks/useGetTemplateStyles';
import { Container, Content, Head, Text } from './PrivacyPolicyContent.style';

const PrivacyPolicyContent: React.FC = () => {
    const { sizeH1, sizeH2, sizeSubTitle, marginH2 } = useGetTemplateStyles();

    return (
        <Container>
            <Head>
                <Title size={sizeH1} text="Privacy Policy" margin="0 0 16px" />
                <SubTitle size={sizeSubTitle} text="We keep your information safe and secure" />
            </Head>
            <Content>
                <Text>
                    Your privacy is important, so Salesmsg has created the following Privacy Policy to let you know what
                    information we collect when you visit our website, why we collect it and how it is used.
                </Text>
                <Text>
                    The terms &quot;you,&quot; &quot;your,&quot; and &quot;yours&quot; refer to the customer using our
                    website. The terms &quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; and &quot;our&quot; refer to
                    Salesmsg. This Privacy Policy is governed by our Terms of Service. By using this website, you
                    consent to the data practices described in this statement. We may periodically make changes to this
                    Privacy Policy that we will include on this page. It is your responsibility to review this Salesmsg
                    Privacy Policy frequently and remain informed about any changes to it, so we encourage you to visit
                    this page often.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="How do we use this information?"
                    margin={marginH2}
                />
                <Text>
                    This information is used for internal marketing purposes only. Salesmsg does not sell, exchange, or
                    release your personal information (name, e-mail address, mailing address, credit data) Your
                    information is not shared with any other third party or company outside of billing and shipping
                    purposes.
                </Text>
                <Text>
                    When you visit our website ‍and use our services, you trust us with your personal information. We
                    provide services that reside inside and consume data from other online platforms (such as Slack or
                    HubSpot, collectively referred to as “Platforms”). When adding the relevant Services to the
                    Platforms, we request permissions which allow sharing of information such as account and profile
                    information or settings of the relevant Platform.
                </Text>
                <Text>
                    This privacy policy applies to all information collected through our website (such as and/or any
                    related services, sales, marketing or events (we refer to them collectively in this privacy policy
                    as the “Services”).
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="What are cookies and how does Salesmsg use them?"
                    margin={marginH2}
                />
                <Text>
                    Cookies help track a person&prime;s &quot;session&quot; while online. By showing how and when our
                    visitors use this Site, this information can help us to continue to improve our Site. We will only
                    use cookies to view information on your hard drive that was put there by a cookie from this Site.
                    The use of cookies is an industry standard and many web sites use them. Cookies are stored on your
                    computer and not on this Site. If you do not wish to receive cookies, or want to be notified of when
                    they are placed, you may set your web browser to do so, if your browser permits.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Is The Information Shared or Disclosed?"
                    margin={marginH2}
                />
                <Text>
                    Salesmsg is not in the business of selling your information. We consider this information to be a
                    vital part of our relationship with you. There are, however, certain circumstances in which we may
                    share your personally identifiable information with certain third parties without further notice to
                    you, as set forth below:
                </Text>
                <Text>
                    <strong>Third Party Applications and Services:</strong> If you elect to use one or more third party
                    applications that interoperate with Salesmsg&prime;s service via Salesmsg, those applications may
                    upon your election be given access to data (which may include personally identifiable information)
                    on Salesmsg&prime;s systems generated in connection with your use of Salesmsg&prime;s service.
                    Please remember that Salesmsg is not responsible for the policies and practices of third party
                    application providers, and Salesmsg shall have no liability arising from any action of any such
                    provider as it concerns your data or information or otherwise. In connection with your use of any
                    such third party applications, please review carefully the applicable privacy policies each such
                    third party application provider to become familiar with their policies and practices as it concerns
                    your data and information.
                </Text>
                <Text>
                    <strong>Business Transfers:</strong> As we develop our business, we might sell or buy businesses or
                    assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event,
                    personally identifiable information may be part of the transferred assets. You acknowledge that such
                    transfers may occur, and that any acquirer or successor of Salesmsg may continue to use your
                    information as set forth in this policy.
                </Text>
                <Text>
                    <strong>Related Companies:</strong> We may also share your personally identifiable information with
                    any entity that is affiliated with us for purposes consistent with this Privacy Policy.
                </Text>
                <Text>
                    <strong>Agents, Consultants and Related Third Parties:</strong> Salesmsg, like many businesses,
                    sometimes uses other companies to perform certain business-related functions. Examples of such
                    functions include mailing information, maintaining databases and processing payments. When we employ
                    another company to perform a function of this nature, we only provide them with the information that
                    they need to perform their specific function.
                </Text>
                <Text>
                    <strong>Legal Requirements and Other Circumstances:</strong> Salesmsg may disclose your personally
                    identifiable information if required to do so by law or in the good faith belief that such action is
                    necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of
                    Salesmsg, (iii) act in urgent circumstances to protect the personal safety of users of the Salesmsg
                    website or services or the public, or (iv) protect against legal liability.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Is our contact database secure?"
                    margin={marginH2}
                />
                <Text>
                    We will never sell, rent or market to your personal contact information for any marketing purposes
                    whatsoever. Your information is 100% safe, secure, and all information is encrypted and transmitted
                    without risk using a Secure Sockets Layer protocol.
                </Text>
                <Text>
                    Any information sent to Salesmsg for text messaging and or storage purposes will remain the property
                    of the account holder, which Salesmsg will hold securely in accordance with our internal security
                    policy and the law. Salesmsg will at no time collect or redistribute this information without your
                    consent, except where legally required to do so by law.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="How does Salesmsg protect our privacy?"
                    margin={marginH2}
                />
                <Text>
                    Your information is stored at the list server that delivers Salesmsg newsletters. Your information
                    can only be accessed by those who help manage those lists in order to deliver e-mail to those who
                    would like to receive Salesmsg&prime;s newsletters.
                </Text>
                <Text>
                    All of the newsletters that are sent to you by Salesmsg include an unsubscribe link in them. You can
                    remove yourself at any time from our newsletters by clicking on the unsubscribe link. You can also
                    contact us at support (at) SalesMessage.com to change your information at any time.
                </Text>
                <Text>
                    This policy may be changed at any time at Salesmsg&prime;s discretion. If we should update this
                    policy, we will post the updates to this page on our Website.
                </Text>
                <Text>
                    If you have any questions or concerns regarding our privacy policy please direct them to support
                    (at) SalesMessage.com.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Links to Other Sites"
                    margin={marginH2}
                />
                <Text>
                    This website may contain links to other sites that are not owned or controlled by us. Please be
                    aware that Salesmsg is not responsible for the privacy policies of such other sites. We encourage
                    you to be aware when you leave our site and to read the privacy statements of each and every website
                    that collects personally identifiable information. This privacy policy applies only to information
                    collected by this website.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Your Consent To This Privacy Policy"
                    margin={marginH2}
                />
                <Text>
                    By using Salesmsg, you agree to this Privacy Policy. This is our entire and exclusive Privacy Policy
                    and it supersedes any earlier version. Our Terms of Service take precedence over any conflicting
                    Privacy Policy provision. We may change this Privacy Policy by posting a new version of this Privacy
                    Policy on this website which it is your responsibility to review. We encourage you to periodically
                    review this Privacy Policy to stay informed about how we are protecting the personally identifiable
                    information we collect. Your continued use of this website or Salesmsg&prime;s services constitutes
                    your agreement to this Privacy Policy and any updates.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="How is my data safe?"
                    margin={marginH2}
                />
                <Title
                    size="26px"
                    textAlign={TextAlignType.LEFT}
                    as="h3"
                    text="Complete control over who can access your Data"
                    margin="32px 0 16px"
                />
                <Text>
                    All entered Data is private by default. Accessing your Data requires a user to have a Salesmsg
                    account and be invited to view and modify your Data by an authorized user of your account.
                </Text>
                <Text>
                    You have a right to request deletion of your personal information by us. Deletion of the information
                    is accomplished by the one way obfuscation. If you would like to request your personal information
                    deletion, please contact us at{' '}
                    <a href="mailto:support@salesmessage.com">support@salesmessage.com</a>
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Where is my data stored and how long is it retained?"
                    margin={marginH2}
                />
                <Text>
                    All Salesmsg data is stored in the US (AWS datacenter).{' '}
                    <a href="https://aws.amazon.com/security">More on AWS security.</a> Salesmsg uses{' '}
                    <a href="https://aws.amazon.com/rds/">Amazon Relational Database Service (Amazon RDS)</a> to store
                    user data in the cloud. Salesmsg encrypts the database data stored on Salesmsg’s Amazon RDS{' '}
                    <a href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Overview.DBInstance.html">
                        DB instance
                    </a>{' '}
                    under a customer master key (CMK) in AWS KMS. User data is retained only as long as it is necessary
                    to properly operate Salesmsg application.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Legal Disclaimer" margin={marginH2} />
                <Text>
                    Salesmsg operates &quot;AS-IS&quot; and &quot;AS-AVAILABLE,&quot; without liability of any kind.
                    Salesmsg is not responsible for events beyond our direct control.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Contacting Salesmsg"
                    margin={marginH2}
                />
                <Text>
                    If you believe that Salesmsg has not adhered to this Privacy Policy, please contact us by email at{' '}
                    <a href="mailto:support@SalesMessage.com">support@SalesMessage.com</a> or by postal mail at
                    SalesMessage, Inc., 1045 E. Atlantic Ave #202, Delray Beach, FL 33483 and we will use commercially
                    reasonable efforts to remedy the problem.
                </Text>
                <Text>
                    Last Updated
                    <br /> May 9, 2020
                </Text>
            </Content>
        </Container>
    );
};

export default PrivacyPolicyContent;
