import { ValueOf } from 'shared/types/general';

export interface IUploadFileCardProps {
    id?: number;
    index: number;
    title: string;
    value?: number;
    type: Type;
    options?: string[];
    previews?: string[][];
    selectedFields?: ISelectedField[];
    setSelectedField?: (field: ISelectedField) => void;
    removeFile?: (id: number) => void;
}

export interface ISelectedField {
    index: number;
    id: number;
}

export interface IOption {
    value: number;
    label: string;
    item: string;
}

export const Type = {
    ERROR: 'Error',
    SUCCESS: 'Success',
    LOADING: 'Loading',
} as const;

type Type = ValueOf<typeof Type>;
