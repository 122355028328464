import React, { useState } from 'react';
import { PATHS } from 'shared/consts/paths';
import { Button, ButtonContent, Link, Navigation, NavItem, NavList } from './MobileNavigation.style';

const MobileNavigation: React.FC = () => {
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

    const handleClick = () => {
        setIsShowMenu(old => !old);
    };

    return (
        <>
            <Button>
                <ButtonContent isActive={isShowMenu} onClick={handleClick} />
            </Button>
            {isShowMenu && (
                <Navigation>
                    <NavList>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_UPLOAD} onClick={handleClick}>
                                Upload
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_HOW_IT_WORK} onClick={handleClick}>
                                How it works
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link smooth to={PATHS.HOME_FAQS} onClick={handleClick}>
                                FAQs
                            </Link>
                        </NavItem>
                    </NavList>
                </Navigation>
            )}
        </>
    );
};

export default MobileNavigation;
