import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ReactGA from 'react-ga4';
import { alertError } from 'shared/lib/functions/alert';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { getFileDataAsBase64String } from 'shared/lib/functions/getFileDataAsBase64String';
import { addFile } from 'entities/Upload/store/upload/reducers/ActionCreator';
import { PATHS } from 'shared/consts/paths';
import { VectorIcon, Title, SubTitle, Uploader, BasicLoader } from 'shared/ui';
import { IPreparedFile } from 'shared/ui/Uploader/Uploader.types';
import { Container, IconList, IconItem, Description } from './UploadSection.style';

const icons = ['cellphone', 'telephone', 'bitphone'];

const UploadSection = () => {
    const [filesUploaded, setFilesUploaded] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [preparedFiles, setPreparedFiles] = useState<IPreparedFile[]>([]);
    const { token } = useAppSelector(state => state.uploadReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleFilesUploadedAll = (files: File[]) => {
        setFilesUploaded(files);
    };

    const handleFilesAdd = useCallback(async (filesToAdd: File[]) => {
        const currentFiles: IPreparedFile[] = [];

        for await (const file of filesToAdd) {
            if (file.type !== 'text/csv') {
                alertError('', 'Please use the file type: csv');

                return;
            }

            if (file.size === 0) {
                alertError('', 'File is Empty');
            } else {
                currentFiles.push({
                    name: file.name,
                    file: file,
                    encodedData: await getFileDataAsBase64String(file),
                });
            }
        }

        setPreparedFiles(currentFiles);
    }, []);

    const handleLoad = useCallback(() => {
        if (token) {
            let count = 0;

            preparedFiles.forEach(file => {
                setIsLoading(true);
                const formData = new FormData();

                formData.append('file', file.file);

                const addFileStatus = dispatch(
                    addFile({
                        formData,
                        token,
                    }),
                );

                addFileStatus
                    .then(state => {
                        count = count + 1;

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if (state && state.error) {
                            setIsLoading(false);
                        } else if (count === preparedFiles.length) {
                            ReactGA.event({
                                category: 'PhoneCheckr',
                                action: 'File Uploaded',
                            });

                            navigate(PATHS.UPLOAD);
                        }
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            });
        }
    }, [preparedFiles]);

    useEffect(() => {
        if (filesUploaded.length) {
            handleFilesAdd(filesUploaded);
        }
    }, [filesUploaded]);

    useEffect(() => {
        if (preparedFiles.length) {
            handleLoad();
        }
    }, [preparedFiles]);

    return (
        <Container id="upload">
            <IconList>
                {icons.map(icon => {
                    return (
                        <IconItem key={uuid()}>
                            <VectorIcon size={[40, 40]} icon={icon} />
                        </IconItem>
                    );
                })}
            </IconList>
            <Description>
                <Title text="Check every phone number" margin="0 0 8px" />
                <SubTitle text="Mobile, landline or VOIP? Upload your phone numbers to find out" />
            </Description>
            <Uploader isShowInformation onFilesUploadedAll={handleFilesUploadedAll} />
            {isLoading && <BasicLoader isCenter isFullScreen />}
        </Container>
    );
};

export default UploadSection;
