import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ValueOf } from 'shared/types/general';

export interface IMarkerProps {
    status: MarkerType;
    markerSize: number;
    iconSize?: number | string | Array<number>;
    icon?: string | IconProp;
}

export const MarkerType = {
    COMPLETED: 'Completed',
    ACTIVE: 'Active',
    NOT_PROCESSED: 'NotProcessed',
} as const;

type MarkerType = ValueOf<typeof MarkerType>;
