import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    padding: 10px 20px;
    @media (max-width: 415px) {
        padding: 10px 15px;
    }
`;

export const Content = styled.div`
    ${generalFlexStyles};
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    border-radius: 12px;
    padding: 96px 0 88px;
    @media (max-width: 767px) {
        padding: 86px 0 80px;
    }
    @media (max-width: 415px) {
        padding: 40px 0 0;
    }
`;

export const Description = styled.article`
    ${generalFlexStyles};
    flex-direction: column;
    margin-bottom: 80px;
    @media (max-width: 415px) {
        margin-bottom: 30px;
    }
`;

export const FaqList = styled.ul`
    width: 100%;
    ${generalFlexStyles};
    flex-direction: column;
    max-width: 690px;
    margin: 0;
    padding: 0;
`;

export const AccordionContent = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7e868d;
    max-width: 580px;
    margin: 0;
`;

export const FaqItem = styled.li`
    width: 100%;
    margin-bottom: 8px;
    ${generalFlexStyles};
    &:last-child {
        margin-bottom: 0;
    }
`;
