import React from 'react';
import { SubTitle, Title } from 'shared/ui';
import { TextAlignType } from 'shared/types/general';
import useGetTemplateStyles from 'shared/lib/hooks/useGetTemplateStyles';
import { Container, Content, Head, Text, List, Item } from './TermsContent.style';

const TermsContent: React.FC = () => {
    const { sizeH1, sizeH2, sizeSubTitle, marginH2 } = useGetTemplateStyles();

    return (
        <Container>
            <Head>
                <Title size={sizeH1} text="Terms of Use" margin="0 0 16px" />
                <SubTitle size={sizeSubTitle} text="The general terms for using our software and website" />
            </Head>
            <Content>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Permitted Use of the Site and Service"
                    margin={marginH2}
                />
                <Text>
                    This Site is provided as on an .as is. basis by Salesmsg for the convenience of its customers and
                    users, and the Site and the domain name(s) associated with it and all copyrights, trademarks and
                    other proprietary and personal rights of the Site and Service are the sole property of Salesmsg. The
                    Site and Service are intended for use by persons 18 years of age or older. You are permitted to
                    access the Site, the Service and the Content provided by Salesmsg (which may include text, images,
                    hosted software, sound files, video or other content, and may be provided via the Site or otherwise)
                    solely for the purpose of receiving information about Salesmsg&prime;s business and products,
                    purchasing and utilizing the Service, communicating with Salesmsg, entering prize promotions offered
                    by SalesMessage, Inc or otherwise as stated on the Site.
                </Text>
                <Text>
                    SalesMessage, Inc hereby grants a worldwide, fully paid-up, perpetual, non-exclusive,
                    non-transferable license to you to use the Site and Service solely for the purpose of operating the
                    Site.
                </Text>
                <Text>
                    You may not copy, modify, reproduce, retransmit or otherwise utilize the Content in any other
                    fashion or for any other purpose without the express written permission of the Content.s owner. You
                    may only link to the Site for the purpose of directing other users to the Site, and must remove any
                    link to the Site immediately on request from SalesMessage, Inc. All copyrights, trademarks, patents,
                    trade secrets and other proprietary rights contained within the Site are the sole property of
                    Salesmsg and/or its licensors, each of whom reserves all rights with regard to such materials.
                </Text>
                <Text>
                    Salesmsg may at its option provide you with one or more areas within the Site for online discussions
                    (e.g. message boards, wikis, chat rooms or blogs). Should you choose to participate in such a forum,
                    you agree not to utilize the forum for illegal or inappropriate purposes. Salesmsg reserves the
                    right but will not be obligated to edit or delete postings to its forums at any time and for any
                    reason. Salesmsg shall also have no responsibility or liability for any content created or posted by
                    you or other third parties within any online forum.
                </Text>
                <Text>
                    You hereby grant Salesmsg a worldwide, perpetual, non-exclusive, transferable, fully-paid license to
                    use, copy, perform, or revise any content provided by you to Salesmsg via the Site (which is
                    referred to in these Terms as &quot;User Content&quot;), including but not limited to any forum
                    posts you may create. In connection with such User Content, you warrant and represent to Salesmsg
                    that you have all rights, title and interests necessary to provide such User Content to Salesmsg,
                    and that your provision of the User Content to Salesmsg shall not infringe any partys proprietary or
                    personal rights, including but not limited to any trademark, copyright, patent, or trade secret.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Disclaimers" margin={marginH2} />
                <Text>
                    Salesmsg makes no warranties, express or implied, with regard to the Site or the Service, their
                    operation, or your use of the Site or Service, including but not limited to warranties of
                    merchantability or fitness for a particular purpose. Salesmsg makes no warranties as to the
                    availability, up-time, functionality, error-free nature or reliability of the Site or the Service,
                    the Internet, the telephone network through which the Service is utilized, or other technology
                    utilized to present, access or utilize the Site or the Service, nor does it warrant that the Site or
                    Service shall be free of computer viruses or other malicious content. Salesmsg reserves the right to
                    modify, suspend or discontinue the offering of the Site and Service at any time for any reason
                    without prior notice. Further, while Salesmsg utilizes electronic and physical security to reduce
                    the risk of improper access to or manipulation of data during transmission and storage, it cannot
                    guarantee the security or integrity of the data and shall have no liability for breaches of security
                    or integrity or third-party interception in transit, nor for any damage which may result to your
                    computer or other property by your use of the Site or Service.
                </Text>
                <Text>
                    IN NO EVENT SHALL SALESMSG BE LIABLE TO YOU FOR ANY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DIRECT,
                    CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF DATA, LOSS
                    OF PROFITS OR LOST SAVINGS, ARISING OUT OF THESE TERMS, THE SITE OR THE SERVICE, EVEN IF SALESMSG
                    HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, IN NO EVENT SHALL SALESMSG&prime;S
                    AGGREGATE LIABILITY FOR ANY REASON ARISING OUT OF THESE TERMS, THE SITE OR THE SERVICE FROM ALL
                    CAUSES OF ACTION OF ANY KIND, INCLUDING CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
                    BREACH OF WARRANTY, MISREPRESENTATION, OR OTHERWISE, EXCEED THE AMOUNT PAID BY YOU TO SALESMSG
                    HEREUNDER.
                </Text>
                <Text>
                    Salesmsg may provide links to third party Web sites from the Site. Such links are provided for your
                    convenience, and do not necessarily constitute an endorsement by Salesmsg of such sites or an
                    affiliation between Salesmsg and the owners of such sites, nor shall Salesmsg be liable for the
                    contents of such sites. Salesmsg will also not be liable for any errors in such links, or for any
                    malfunction of such links.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Payments" margin={marginH2} />
                <Text>
                    Your use of the Service is contingent on your paying in advance for such use, in the amounts and
                    using the methods then-current on the Site. Salesmsg will charge your indicated method of payment
                    immediately upon your confirming the payment method and amount. Amounts to be charged may include
                    sales and other taxes where applicable and any other amounts indicated on the Site. To the extent
                    that you are responsible for any additional taxes or fees beyond those collected by Salesmsg, you
                    agree that you will pay them when due.
                </Text>
                <Text>
                    Salesmsg shall not be responsible for any errors or transmission failures with regard to the
                    charging and collection of funds from your indicated payment method, nor for any actions taken by
                    the provider of the payment method you choose (which could include refusal to authorize the charge).
                    In addition to these Terms, any payments made by you may be subject to the agreement between you and
                    the provider of the payment method.
                </Text>
                <Text>
                    Payments to Salesmsg may be prepaid via Credit Card, check, or ACH. Funds added to your account are
                    converted to prepaid credits and are added to your account balance. All prepaid credits shall have
                    an expiration date linked to the date of purchase (or most recent date of account recharge), at
                    which point it shall expire automatically. All credits will expire 12 months after the
                    aforementioned date. Any unused value shall be forfeited upon expiration.
                </Text>
                <Text>
                    As between you and Salesmsg, you are responsible for all charges related to purchases made using
                    your account and payment method, whether or not you authorized such purchases.
                </Text>
                <Text>
                    A. Payment by Credit Card / Paypal. You hereby authorize Salesmsg to charge your credit card as set
                    forth in the Payment Method section of the registration form to a monthly Services fee, and overage
                    fees to Salesmsg at the rates set forth on{' '}
                    <a href="https://www.salesmessage.com/">salesmessage.com</a> or agreed upon rate.
                </Text>
                <Text>
                    B. Pass Through Charges. If at any time after execution of this Agreement, a Network Operator or
                    other Third-Party changes the Third-Party Charges on Provisioned Phone Numbers then Salesmsg, at its
                    discretion, may pass through such fees (“Pass Through Charges”) to you at cost.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Trademarks" margin={marginH2} />
                <Text>
                    Salesmsg, the Salesmsg logo, and other Salesmsg trademarks, service marks, graphics and logos used
                    in connection with Salesmsg are trademarks or registered trademarks of SalesMessage, Inc in the
                    United States and/or other countries. Other trademarks, service marks, graphics and logos used in
                    connection with Salesmsg may be the trademarks of their respective owners.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Copyright Policy" margin={marginH2} />
                <Text>
                    It is the policy of SalesMessage, Inc to terminate access to Salesmsg by any user who repeatedly
                    infringes the copyright rights of others upon receipt of prompt notification of such infringement to
                    us by the copyright owner or the copyright owner&prime;s legal agent. If you believe that your work
                    is been hosted on Salesmsg in a way that constitutes copyright infringement, please provide us with
                    the following information:
                </Text>
                <Text>
                    an electronic or physical signature of the person authorized to act on behalf of the owner of the
                    copyright interest; a description of the copyrighted work that you claim has been infringed; a
                    description of where the material that you claim is infringing is located on Salesmsg; your address,
                    telephone number, and email address; a written statement by you that you have a good faith belief
                    that the disputed use is not authorized by the copyright owner, its agent, or the law; and a
                    statement by you, made under penalty of perjury, that the above information in your notice is
                    accurate and that you are the copyright owner or authorized to act on the copyright owner&prime;s
                    behalf.
                </Text>
                <Text>Our agent for notices of claimed copyright infringement may be reached as follows:</Text>
                <Text>
                    SalesMessage, Inc
                    <br />
                    Attn: Copyright Agent
                    <br />
                    1045 E. Atlantic Ave #202
                    <br />
                    Delray Beach, FL 33483
                    <br />
                    Email: <a href="mailto:support@salesmessage.com">support@salesmessage.com</a>
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="User Warranties; Indemnification"
                    margin={marginH2}
                />
                <Text>
                    By using this Site and the Service, you hereby warrant and represent that you have all necessary
                    rights, power and authority to agree to these Terms and perform your obligations hereunder, and
                    nothing contained in these Terms or in the performance of such obligations will place you in breach
                    of any other contract or obligation. You further warrant and represent to Salesmsg that you are and
                    shall at all times remain in full compliance with all applicable laws and regulations with regard to
                    your use of this Site and Service, including without limitation the Telephone Consumer Protection
                    Act, the Telemarketing Sales Rule, the CAN-SPAM Act (Controlling the Assault of Non-Solicited
                    Pornography and Marketing Act) of 2003, and all other laws and regulations concerning privacy,
                    telemarketing and Internet marketing.
                </Text>
                <Text>
                    By using this Site and the Service, you agree to indemnify and hold harmless Salesmsg, its
                    affiliates and contractors, and its and their officers, directors, employees and agents from and
                    against any loss, claim, damage, obligation, liability, cost or expense (including, without
                    limitation, reasonable attorneys. fees and costs of any investigation or preparation) arising out of
                    or in connection with any actual or threatened claim, suit, action or proceeding by any person based
                    on or arising out of your breach of the above warranties or any use by you or an account or computer
                    owned by you of the Site, the Service or the Content or your provision of User Content to Salesmsg.
                </Text>
                <Text>
                    These Terms shall be governed by and interpreted in accordance with the laws of the State of
                    Florida, United States of America. You agree that the exclusive forum for any disputes arising out
                    of these Terms and/or your use of the Site or Service shall be the state and federal courts located
                    in Palm Beach, Florida.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Responsible Use" margin={marginH2} />
                <Text>
                    Customer agrees to familiarize him or herself with the legalities of any campaigns run through the
                    Salesmsg system, by visiting the following websites:
                </Text>
                <List>
                    <Item>
                        Federal Trade Commission: <a href="http://www.ftc.gov">http://www.ftc.gov</a>
                    </Item>
                    <Item>
                        Federal Communications Commission: <a href="http://www.fcc.gov">http://www.fcc.gov</a>
                    </Item>
                    <Item>
                        DoNotCall Registry Info: <a href="http://www.donotcall.gov">http://www.donotcall.gov</a>
                    </Item>
                    <Item>
                        Customer agrees to familiarize themselves with any additional International, Federal, State, or
                        local laws governing your dialing.
                    </Item>
                    <Item>
                        Customer agrees to place *ANY* individual requesting DNC status, on Customer&prime;s accounts
                        DNC list, immediately.
                    </Item>
                    <Item>
                        Customer agrees to provide legal contact information in any outbound campaign within the initial
                        greeting message.
                    </Item>
                    <Item>Customer agrees to provide own sound files for all outbound campaigns.</Item>
                    <Item>
                        Customer agrees to provides all data and with this ability agrees to responsibly dial each
                        campaign with courtesy to the recipients for which it is meant.
                    </Item>
                    <Item>Customer agrees to schedule campaigns responsibly.</Item>
                    <Item>
                        Customer agrees not to send any calls to life-line services, such as hospitals, fire, police,
                        911, or utility related telephone numbers.
                    </Item>
                    <Item>
                        Customer agrees to not send any sales outbound broadcasts to recipients that have not consented
                        to receiving such a broadcast.
                    </Item>
                    <Item>Customer agrees to use DNC / opt-out features made available to you via CallLoop.</Item>
                    <Item>
                        Customer agrees to consult with an attorney before dialing any data for which customer is
                        unfamiliar, or if legalities of dialing remain unclear to Customer.
                    </Item>
                </List>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Other Provisions" margin={marginH2} />
                <Text>
                    Customer acknowledges and agrees to be held liable for any and all damages caused to Salesmsg by
                    Customer, its officers, agents and/or employees, as a direct result of a violation in State,
                    Federal, and tariff regulations. Damages include but are not limited to those damages that may arise
                    from Customer&prime;s fraudulent, intentional or unintentional harm, disability, unauthorized use
                    of, or destruction to any and all equipment, licensing and/or services provided by Salesmsg to
                    Customer.
                </Text>
                <Text>
                    The provisions regarding ownership; payments; warranties; and indemnification set forth above will
                    survive any termination of these Terms. All notices to Salesmsg in connection with these Terms shall
                    be provided in writing, and any notice will be deemed to be given on the date it is received.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Express Consent and Express Written Consent"
                    margin={marginH2}
                />
                <Text>
                    You will not send, cause to be sent or facilitate the sending of any message using the Service if
                    the recipient to receive such message has not given you prior express consent. However, in the case
                    that you are sending messages that are Spam, you must have acquired the prior express written
                    consent from the recipient to receive such message. Under no circumstances will You send Spam or any
                    other message where You obtained the consent of the recipient as a condition to a purchase.
                </Text>
                <Text>
                    We will send notice to the email on record if a card is declined. We may also follow-up by phone or
                    mail. We reserve the right to send delinquent accounts to a collection agency if they are not paid
                    within 3 months.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Retrieving Your Data After Your Account Has Been Cancelled"
                    margin={marginH2}
                />
                <Text>
                    Per our privacy policy, data from cancelled accounts will be deleted immediately and cannot be
                    retrieved. Please be sure to export all data you wish to save before canceling your account.
                </Text>
                <Text>
                    The terms &quot;you,&quot; &quot;your,&quot; and &quot;yours&quot; refer to the customer. The terms
                    &quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; and &quot;our&quot; refer to Salesmsg.
                    (&quot;Salesmsg&quot;). These General Terms and Conditions are governed by our Terms of Service. Our
                    Terms of Service take precedence over any conflicting General Terms and Conditions provision. BY
                    USING SALESMSG&prime;S SERVICES AND THE SALESMSG WEBSITE, YOU CONSENT TO THE TERMS AND CONDITIONS OF
                    THESE General Terms and Conditions. IF YOU DO NOT AGREE TO THESE General Terms and Conditions PLEASE
                    DO NOT USE THE WEBSITE AND/OR SERVICES.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Accuracy and Completeness of Information"
                    margin={marginH2}
                />
                <Text>
                    While Salesmsg strives to ensure that the information contained on this Website is accurate and
                    reliable, Salesmsg makes no warranties as to the accuracy, correctness or completeness of any such
                    information and assumes no liability or responsibility for any omissions or errors in the content of
                    this Website. Salesmsg reserves the right to revise the information contained on this Website at any
                    time, in its sole discretion without any obligation to notify past, current or prospective visitors.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Your Use of This Website"
                    margin={marginH2}
                />
                <Text>
                    You may download certain content that appears on this Website for your personal use, provided you do
                    not remove or modify any copyright, trademark or other proprietary notices. You expressly agree that
                    no right, title or interest in any downloaded materials is transferred to you as a result of such
                    downloading or copying. There are inherent dangers in downloading materials and information from the
                    Internet, and Salesmsg cautions you to make sure that you completely understand the potential risks
                    before downloading any such content. You are solely responsible for adequate protection and backup
                    of the data and equipment used in connection with any information downloaded from this Website, and
                    Salesmsg will not be liable for any damages that you may suffer as a result of such download.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Changes To The General Terms and Conditions"
                    margin={marginH2}
                />
                <Text>
                    We reserve the right to change these General Terms and Conditions from time to time without notice
                    by posting the changes to Salesmsg&prime;s website. When we do, we will also revise the &quot;last
                    update&quot; date of these General Terms and Conditions.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Privacy Policy" margin={marginH2} />
                <Text>
                    Salesmsg&prime;s use of any personally identifiable information you submit to the Website is
                    governed by the Website&prime;s Privacy Policy.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Disclaimers" margin={marginH2} />
                <Text>
                    THE INFORMATION ON THIS SITE IS PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </Text>
                <Text>
                    No advice or information, whether oral or written, obtained by a user from Salesmsg, shall create
                    any warranty not expressly made herein.
                </Text>
                <Text>
                    If you download or copy any material from this website, you do so at your sole discretion and risk
                    and consequently you will be responsible for any damage to your computer system or loss of data that
                    results from the download or copying of any material or software.
                </Text>
                <Text>
                    Some of Salesmsg&prime;s Services require the use of third party services, products, or networks.
                    Salesmsg will make commercially reasonable efforts to communicate any policies, requirements, or
                    guidelines of those third parties to You. You agree to follow those policies, requirements, or
                    guidelines. ANY ACTUAL OR ALLEGED VIOLATION OF A THIRD PARTY POLICY, REQUIREMENT, OR GUIDELINE BY
                    YOU IS YOUR RESPONSIBILITY. SALESMSG MAKES NO WARRANTY OR REPRESENTATION THAT ITS WEBSITE OR
                    SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE IF ANY VIOLATION OF A THIRD PARTY
                    POLICY, REQUIREMENT, OR GUIDELINE BY YOU IS ALLEGED BY A THIRD PARTY.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Limitation of Liability"
                    margin={marginH2}
                />
                <Text>
                    IN NO EVENT WILL SALESMSGSalesmsg BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL
                    OR CONSEQUENTIAL DAMAGES HOWEVER THEY MAY ARISE, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
                    OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE: (i) USE OF THE INFORMATION
                    CONTAINED ON THIS WEBSITE; (ii) USE OF SOFTWARE DOWNLOADED OR LINKED TO FROM THIS WEBSITE; OR (iii)
                    FOR THE FAILURE TO PROVIDE SERVICES OR INFORMATION AVAILABLE FROM THIS WEBSITE, EVEN IF
                    SALESMSGSalesmsg HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </Text>
                <Text>
                    IN THE EVENT YOU HAVE ANY DISPUTE WITH ONE OR MORE THIRD PARTIES AS A RESULT OF YOUR USE OF THE
                    WEBSITE, CONTENT OR SERVICES, OR ARE IN ANY WAY DAMAGED AS A RESULT OF ANY THIRD PARTY IN CONNECTION
                    THEREWITH, YOU HEREBY RELEASE AND COVENANT NOT TO SUE OR OTHERWISE MAKE A CLAIM, DEMAND OR FILE ANY
                    LEGAL ACTION OR INSTITUTE ANY LEGAL OR REGULATORY PROCEEDINGS AGAINST SALESMSG, ITS AFFILIATES,
                    OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES AND SUPPLIERS FROM, FOR ANY CLAIMS, ACTIONS,
                    DEMANDS OR DAMAGES (WHETHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL), OF WHATEVER
                    KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, WHETHER FORESEEABLE OR NOT, DISCLOSED OR
                    UNDISCLOSED.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Indemnification" margin={marginH2} />
                <Text>
                    You agree to defend, indemnify and hold Salesmsg harmless from and against any and all third party
                    claims, damages, costs and expenses, including reasonable attorney&prime;s fees, arising from or
                    related to your use of this website.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Links to Third Party Websites"
                    margin={marginH2}
                />
                <Text>
                    Any links to third party sites are provided as a convenience to you, and such sites are neither
                    owned nor operated by Salesmsg. Salesmsg has no control over these linked sites, is not responsible
                    for the contents of these sites, makes no representations or warranties with respect to these linked
                    sites, and shall not be liable for any damages or injury arising from the content of these linked
                    sites. Your viewing and use of any third party sites is at your sole discretion and risk.
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Copyright Notice" margin={marginH2} />
                <Text>
                    Unless otherwise noted, the graphic images, buttons and text contained in this website are the
                    exclusive property of Salesmsg and are Copyright 2017 Salesmsg, Inc. All rights reserved. Except for
                    your personal use as permitted herein, these items may not be copied, displayed, transmitted or
                    reproduced in any form without the express written permission of Salesmsg.
                </Text>
                <Title
                    size={sizeH2}
                    textAlign={TextAlignType.LEFT}
                    as="h2"
                    text="Submissions, Suggestions & Community Participation"
                    margin={marginH2}
                />
                <Text>
                    In the event you elect to communicate to us suggestions for improvements to the Website or any of
                    Salesmsg&prime;s services or properties (collectively, &quot;Feedback&quot;), we shall own all
                    right, title, and interest in and to the same, even if you have designated the Feedback as
                    confidential, and we shall be entitled to use the Feedback without restriction. Furthermore, any
                    other content or information you post or provide to Salesmsg via comments, forums, emails and the
                    like (collectively, &quot;Communications&quot;) shall be considered the property of Salesmsg. You
                    hereby irrevocably assign all right, title and interest in and to the Feedback and Communications to
                    us and agree to provide us such assistance as we may require to document, perfect, and maintain our
                    rights to the Feedback and Communications.‍
                </Text>
                <Title size={sizeH2} textAlign={TextAlignType.LEFT} as="h2" text="Trademark Notice" margin={marginH2} />
                <Text>
                    The Salesmsg logo is a trademark of Salesmsg, Inc. Other company, product, and service names
                    mentioned in these documents may be trademarks and/or service marks of others.
                </Text>
                <Text>
                    Last Updated
                    <br />
                    Updated: October 12, 2020
                </Text>
            </Content>
        </Container>
    );
};

export default TermsContent;
