import { combineReducers, configureStore } from '@reduxjs/toolkit';
import uploadReducer from 'entities/Upload/store/upload/reducers/Upload';
import modalsReducer from 'shared/store/modals/redusers/Modals';

const rootReducer = combineReducers({
    uploadReducer,
    modalsReducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
