import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { sendPaymentsData } from 'entities/Upload/store/upload/reducers/ActionCreator';
import { VectorIcon, Modal, Button, Select } from 'shared/ui';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import useGetCost from 'shared/lib/hooks/useGetCost';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { alertError } from 'shared/lib/functions/alert';
import { modalsSlice } from 'shared/store/modals/redusers/Modals';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import { PATHS } from 'shared/consts/paths';
import {
    Body,
    Content,
    Form,
    CloseButton,
    Info,
    Cost,
    NameInput,
    Group,
    Label,
    stylesFormFieldSelect,
} from './ModalPayment.style';
import { ReportType } from "../Report/Report.types";
import { uploadSlice } from "../../store/upload/reducers/Upload";

export interface IOption {
    value: string;
    label: string;
}

const countryOptions = [
    {
        label: 'United States',
        value: 'US',
    },
    {
        label: 'Canada',
        value: 'CA',
    },
];

const options = {
    style: {
        base: {
            color: '#1A1F36',
            fontFamily:
                "-apple-system, 'Inter'," +
                'BlinkMacSystemFont, ' +
                "'Segoe UI', " +
                'Roboto, Oxygen, ' +
                "Ubuntu, 'Fira Sans', " +
                "'Droid Sans', " +
                "'Helvetica Neue', " +
                'sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#8792A2',
            },
        },
        invalid: {
            color: '#E11900',
            iconColor: '#E11900',
        },
    },
};

const ModalPayment: React.FC = () => {
    const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [selectedRegion, setSelectedRegion] = useState<IOption>({
        label: 'United States',
        value: 'US',
    });
    const { isShow, value: email } = useAppSelector(state => state.modalsReducer);
    const {
        success_rows_count,
        token: initialAppToken,
        isLoadingPayments,
        paymentStatus,
    } = useAppSelector(state => state.uploadReducer);
    const { closeModals } = modalsSlice.actions;
    const { sendDataToHubspot } = uploadSlice.actions;

    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const cost = useGetCost(success_rows_count);

    const closeHandler = (): void => {
        dispatch(closeModals());
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;

        setName(value);
    };

    const handleChangeCard = (e: StripeCardElementChangeEvent) => {
        setIsCardComplete(e.complete);
    };

    const handleChangeRegion = (data: IOption) => {
        setSelectedRegion(data);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
                alertError('', error.message);
            }

            if (token && initialAppToken && email && name) {
                dispatch(
                    sendPaymentsData({
                        token: initialAppToken,
                        email,
                        card_token: token.id,
                        name,
                    }),
                );

                dispatch(
                    sendDataToHubspot({
                        type: ReportType.FULL,
                        email,
                        name,
                    }),
                );
                ReactGA.event({
                    category: 'PhoneCheckr',
                    action: 'Service Purchased',
                    value: cost,
                });
            }
        }
    };

    useEffect(() => {
        if (paymentStatus && paymentStatus.status === 'success') {
            dispatch(closeModals());
            navigate(PATHS.COUPON);
        }

        if (paymentStatus && paymentStatus.status === 'error') {
            alertError('', paymentStatus.message);
        }
    }, [paymentStatus, isLoadingPayments]);

    return (
        <Modal isActive={isShow} onClose={closeHandler}>
            <Body>
                <CloseButton colorType={ButtonColorType.WHITE} onClick={closeHandler}>
                    <VectorIcon size={[20, 20]} icon="close-icon" />
                </CloseButton>
                <Content>
                    <Info>{success_rows_count} phone lookups</Info>
                    <Cost>${cost}</Cost>
                    <Form onSubmit={handleSubmit}>
                        <Group>
                            <Label>Payment information</Label>
                            <CardElement options={options} onChange={handleChangeCard} />
                        </Group>
                        <Group>
                            <Label>Name</Label>
                            <NameInput onChange={handleChangeName} type="text" value={name} />
                        </Group>
                        <Group>
                            <Label>Country or region</Label>
                            <Select
                                value={selectedRegion}
                                options={countryOptions}
                                placeholder="Country or region"
                                additionalStyles={stylesFormFieldSelect}
                                onChange={(option: any) => {
                                    handleChangeRegion(option);
                                }}
                            />
                        </Group>
                    </Form>
                    <Button
                        colorType={ButtonColorType.BLUE}
                        width="100%"
                        onClick={handleSubmit}
                        isDisabled={!stripe || !selectedRegion || !name || !isCardComplete || isLoadingPayments}
                    >
                        Pay ${cost}
                    </Button>
                </Content>
            </Body>
        </Modal>
    );
};

export default ModalPayment;
