import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertError } from 'shared/lib/functions/alert';
import axios from 'axios';
import { API_BASE_URL } from 'shared/consts/api';
import {
    IParams,
    IRequestBasicReportParams,
    IRequestPaymentsDataParams,
    IResponseDownloadInfo,
    IResponseStatus,
    ISessionInfo,
    IToken,
    IUpdatePhoneKeyParams,
    IUploadedFile,
} from '../models/IUpload';

export const sessionNew = createAsyncThunk<IToken, string, { rejectValue: string }>(
    'session/sessionInit',
    async (token, { rejectWithValue }) => {
        try {
            const config = token.length
                ? {
                      headers: {
                          token: token,
                      },
                  }
                : {};

            const response = await axios.post<IToken>(`${API_BASE_URL}/session/new`, null, config);

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err?.message);
        }
    },
);

export const addFile = createAsyncThunk<IUploadedFile, IParams, { rejectValue: string }>(
    'uploads/addFile',
    async ({ formData, token }, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.post<IUploadedFile>(`${API_BASE_URL}/uploads/add-file`, formData, config);

            return response.data;
        } catch (err: any) {
            alertError('', err?.response.data.message);

            return rejectWithValue(err?.response.data.message);
        }
    },
);

export const sessionInfo = createAsyncThunk<ISessionInfo, string, { rejectValue: string }>(
    'session/getSessionInfo',
    async (token, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.get<ISessionInfo>(`${API_BASE_URL}/session/info`, config);

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err?.message);
        }
    },
);

export const checkUploadStatus = createAsyncThunk<IResponseStatus, string, { rejectValue: string }>(
    'uploads/checkUploadStatus',
    async (token, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.post<IResponseStatus>(`${API_BASE_URL}/uploads/check/start`, null, config);

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err?.message);
        }
    },
);

export const removeFile = createAsyncThunk<IResponseStatus, { id: number; token: string }, { rejectValue: string }>(
    'uploads/removeFile',
    async ({ id, token }, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.delete<IResponseStatus>(`${API_BASE_URL}/uploads/${id}`, config);

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err?.message);
        }
    },
);

export const updatePhoneKey = createAsyncThunk<
    IResponseStatus | IUploadedFile,
    IUpdatePhoneKeyParams,
    { rejectValue: string }
>('uploads/updatePhoneKey', async ({ token, index, id }, { rejectWithValue }) => {
    const config = {
        headers: {
            token: token,
        },
    };

    try {
        const response = await axios.put<IResponseStatus | IUploadedFile>(
            `${API_BASE_URL}/uploads/${id}/update-phone-key`,
            index,
            config,
        );

        return response.data;
    } catch (err: any) {
        return rejectWithValue(err?.message);
    }
});

export const requestBasicReport = createAsyncThunk<IResponseStatus, IRequestBasicReportParams, { rejectValue: string }>(
    'downloads/requestBasicReport',
    async ({ token, email }, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.post<IResponseStatus>(
                `${API_BASE_URL}/downloads/request-basic-report`,
                { email },
                config,
            );

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err?.message);
        }
    },
);

export const getDownloadInfo = createAsyncThunk<
    IResponseStatus | IResponseDownloadInfo,
    string,
    { rejectValue: string }
>('downloads/getDownloadInfo', async (hash, { rejectWithValue }) => {
    try {
        const response = await axios.get<IResponseStatus | IResponseDownloadInfo>(
            `${API_BASE_URL}/downloads/info/${hash}`,
        );

        return response.data;
    } catch (err: any) {
        return rejectWithValue(err?.message);
    }
});

export const sendPaymentsData = createAsyncThunk<IResponseStatus, IRequestPaymentsDataParams, { rejectValue: string }>(
    'payments/sendPaymentsData',
    async ({ token, email, card_token, name }, { rejectWithValue }) => {
        const config = {
            headers: {
                token: token,
            },
        };

        try {
            const response = await axios.post<IResponseStatus>(
                `${API_BASE_URL}/payments/pay`,
                { email, card_token, name },
                config,
            );

            return response.data;
        } catch (err: any) {
            alertError('', err?.response.data.message);

            return rejectWithValue(err?.message);
        }
    },
);
