import React, { memo } from 'react';
import { ISubTitleProps } from './SubTitle.types';
import { Container } from './SubTitle.style';

const SubTitleComponent: React.FC<ISubTitleProps> = ({ text, color, size, margin, textAlign, as, maxWidth }) => {
    return (
        <>
            {text && (
                <Container as={as} color={color} size={size} margin={margin} textAlign={textAlign} maxWidth={maxWidth}>
                    {text}
                </Container>
            )}
        </>
    );
};

export const SubTitle = memo(SubTitleComponent);
