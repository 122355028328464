export const TextAlignType = {
    START: 'start',
    END: 'end',
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    JUSTIFY: 'justify',
    JUSTIFY_ALL: 'justify-all',
    MATCH_PARENT: 'match-parent',
    INHERIT: 'inherit',
    INITIAL: 'initial',
    REVERT: 'revert',
    REVERT_LAYER: 'revert-layer',
    UNSET: 'unset',
} as const;

export type TextAlignType = ValueOf<typeof TextAlignType>;

export type ValueOf<T> = T[keyof T];
