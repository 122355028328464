import { ValueOf } from 'shared/types/general';

export interface IRoundCountProps extends IRoundCountStylesProps {
    count: number;
}

export interface IRoundCountStylesProps {
    size?: string;
    type: RoundType;
}

export const RoundType = {
    ERROR: 'Error',
    SUCCESS: 'Success',
} as const;

type RoundType = ValueOf<typeof RoundType>;
