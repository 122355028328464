import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import ProgressBar from 'react-customizable-progressbar';
import { checkUploadStatus, sessionInfo } from 'entities/Upload/store/upload/reducers/ActionCreator';
import { PATHS } from 'shared/consts/paths';
import StepsCount from 'entities/Upload/ui/StepsCount/StepsCount';
// import { MarkerType } from 'shared/ui/Marker/Marker.types';
import { SubTitle, Title, VectorIcon } from 'shared/ui';
import {
    CheckingSuccess,
    Container,
    Description,
    Indicator,
    IndicatorContainer,
    IndicatorInnerContainer,
    Percent,
    ProgressContainer,
} from './CheckingFiles.style';

const CheckingFiles: React.FC = () => {
    const { percentage, status_group, token } = useAppSelector(state => state.uploadReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const getStatus = () => {
        if (percentage && status_group !== 'checking_complete' && token) {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }

            dispatch(sessionInfo(token));
        }
    };

    useEffect(() => {
        if (token) {
            const checkStartStatus = dispatch(checkUploadStatus(token));

            checkStartStatus.then(() => {
                timerRef.current = setTimeout(() => {
                    // Restart checking if freeze
                    getStatus();
                }, 3000);

                dispatch(sessionInfo(token));
            });
        } else {
            navigate(PATHS.HOME);
        }
    }, []);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;

        if (status_group !== 'checking_complete') {
            interval = setInterval(getStatus, 500);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [percentage, status_group]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        if (status_group === 'checking_complete') {
            timeout = setTimeout(() => {
                navigate(PATHS.REPORT);
            }, 1500);
        }

        return () => {
            if (timeout) {
                clearInterval(timeout);
            }
        };
    }, [status_group]);

    return (
        <Container>
            <StepsCount>Step 2/3</StepsCount>
            <Description bigMargin={status_group === 'checking_complete'}>
                <Title
                    text={`Checking ${status_group === 'checking_complete' ? 'Completed' : ''}`}
                    margin="0 0 8px 0"
                />
                <SubTitle text="Thanks for your patience" />
            </Description>
            <ProgressContainer>
                {status_group === 'checking_complete' ? (
                    <CheckingSuccess>
                        <VectorIcon size={[120, 120]} icon="check-success" />
                    </CheckingSuccess>
                ) : (
                    <ProgressBar
                        progress={percentage ? Math.round(percentage) : 0}
                        radius={110}
                        trackStrokeWidth={8}
                        trackStrokeColor="#EDEFF2"
                        strokeWidth={8}
                        strokeColor="#1268FB"
                    >
                        <IndicatorContainer>
                            <IndicatorInnerContainer>
                                <Indicator>{percentage ? Math.round(percentage) : 0}</Indicator>
                                <Percent>%</Percent>
                            </IndicatorInnerContainer>
                        </IndicatorContainer>
                    </ProgressBar>
                )}
            </ProgressContainer>
            {/*<List>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.COMPLETED} />*/}
            {/*        <Type>Country</Type>*/}
            {/*    </Item>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.COMPLETED} />*/}
            {/*        <Type>Type</Type>*/}
            {/*    </Item>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.ACTIVE} />*/}
            {/*        <Type>Carrier</Type>*/}
            {/*    </Item>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.NOT_PROCESSED} />*/}
            {/*        <Type>Network</Type>*/}
            {/*    </Item>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.NOT_PROCESSED} />*/}
            {/*        <Type>Errors</Type>*/}
            {/*    </Item>*/}
            {/*    <Item>*/}
            {/*        <Marker markerSize={32} status={MarkerType.NOT_PROCESSED} />*/}
            {/*        <Type>Valid</Type>*/}
            {/*    </Item>*/}
            {/*</List>*/}
        </Container>
    );
};

export default CheckingFiles;
