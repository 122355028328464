import React, { useEffect, useState } from 'react';
import { RoundCount, VectorIcon, Select } from 'shared/ui';
import { RoundType } from 'shared/ui/RoundCount/RoundCount.types';
import { IOption, IUploadFileCardProps, Type } from './UploadFileCard.types';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import {
    Container,
    LeftContent,
    RightContent,
    Description,
    Name,
    Info,
    ProgressContainer,
    CloseButton,
    SelectWrapper,
    ProgressLoader,
    ProgressLoaderContainer,
    InboxContainer,
    InboxTitle,
    InboxDescription,
} from './UploadFileCard.style';

const InboxOption = (data: any): React.ReactNode => {
    const { label, item } = data;

    return (
        <InboxContainer>
            <InboxTitle>{label}</InboxTitle>
            <InboxDescription>{item}</InboxDescription>
        </InboxContainer>
    );
};

const UploadFileCard: React.FC<IUploadFileCardProps> = ({
    id,
    index,
    title,
    value,
    type,
    options,
    previews,
    selectedFields,
    setSelectedField,
    removeFile,
}) => {
    const [selectedPhoneColumn, setSelectedPhoneColumn] = useState<IOption | null>(null);

    const handleChange = (data: IOption): void => {
        if (id && setSelectedField) {
            const selectedItem = {
                id: id,
                index: data.value,
            };

            setSelectedField(selectedItem);
        }
    };

    const handleRemoveItem = (): void => {
        if (id && removeFile) removeFile(id);
    };

    const prepareOptions = (options: string[], previews: string[]): IOption[] => {
        return options.map((option, index) => {
            return {
                value: index,
                label: option,
                item: previews[index],
            };
        });
    };

    useEffect(() => {
        if (selectedFields) {
            selectedFields.forEach(item => {
                if (item.id === id && options && previews) {
                    setSelectedPhoneColumn({
                        value: item.index,
                        label: options[item.index],
                        item: previews[0][item.index],
                    });
                }
            });
        }
    }, []);

    return (
        <Container>
            <LeftContent>
                {type === Type.LOADING ? (
                    <>
                        <ProgressContainer>
                            <ProgressLoaderContainer>
                                <ProgressLoader />
                            </ProgressLoaderContainer>
                        </ProgressContainer>
                        <Description>
                            <Name>{title}</Name>
                        </Description>
                    </>
                ) : (
                    <>
                        <RoundCount type={RoundType.SUCCESS} count={index} size="40px" />
                        <Description>
                            <Name>{title}</Name>
                            <Info>{value} rows</Info>
                        </Description>
                    </>
                )}
            </LeftContent>
            <RightContent>
                {type !== Type.LOADING && options && previews && (
                    <SelectWrapper>
                        <Select
                            value={selectedPhoneColumn}
                            options={prepareOptions(options, previews[0])}
                            formatOptionLabel={InboxOption}
                            placeholder="Select phone column"
                            onChange={(option: any) => {
                                handleChange(option);
                            }}
                        />
                    </SelectWrapper>
                )}
                <CloseButton
                    colorType={ButtonColorType.BASE}
                    onClick={handleRemoveItem}
                    disabled={type === Type.LOADING}
                >
                    <VectorIcon size={[20, 20]} icon="close-icon" />
                </CloseButton>
            </RightContent>
        </Container>
    );
};

export default UploadFileCard;
