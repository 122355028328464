import styled, { css } from 'styled-components';
import { IInfoStyles } from './ThankSection.types';
import { Container as TitleContainer } from 'shared/ui/Title/Title.style';
import { Container as SubTitleContainer } from 'shared/ui/SubTitle/SubTitle.style';

export const Container = styled.div`
    width: 100%;
    max-width: 808px;
    padding-top: 25px;
`;

export const CompletedLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    margin: 0 auto 24px;
    border: 2px solid #edeff2;
    border-radius: 100%;
`;

export const Description = styled.article`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
    @media (max-width: 415px) {
        margin-bottom: 50px;
    }
`;

export const InfoDescription = styled.article`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 32px;
    @media (max-width: 415px) {
        margin-bottom: 20px;
    }
`;

export const GreenButton = styled.a`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    outline: transparent;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding: 10px 20px;
    background: rgba(0, 172, 110, 0.1);
    color: rgb(6, 138, 90);
    text-decoration: none;
`;

export const Info = styled.div<IInfoStyles>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 65px 30px;
    margin: 0 auto 24px;
    border: 2px dashed #d9dbdf;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
    ${({ isShowBlueBanner }) =>
        isShowBlueBanner &&
        css`
            position: relative;
            border: none;
            background-image: url(/assets/thank-banner.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            ${TitleContainer} {
                color: #ffffff;
            }
            ${SubTitleContainer} {
                color: #ffffff;
            }
            ${GreenButton} {
                background: #dcfff2;
            }

            &:after {
                content: url(/assets/thank-left-decor.svg);
                width: 89px;
                height: 85px;
                position: absolute;
                left: -100px;
                bottom: -50px;
                @media (max-width: 767px) {
                    content: none;
                }
            }
            &:before {
                content: url(/assets/thank-right-decor.svg);
                width: 84px;
                height: 83px;
                position: absolute;
                right: -90px;
                top: -80px;
                @media (max-width: 767px) {
                    content: none;
                }
            }
        `}
`;

export const LightBlueButton = styled.a`
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    outline: transparent;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding: 10px 20px;
    color: rgb(18, 104, 251);
    background: rgba(18, 104, 251, 0.1);
    text-decoration: none;
`;

export const Label = styled.span`
    width: max-content;
    margin-left: 8px;
`;
export const LinkToSalesmsg = styled.a`
    display: inline-flex;
    width: max-content;
    margin-bottom: 32px;
    max-width: 119px;
`;

export const Image = styled.img`
    width: 100%;
`;
