import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    flex-direction: column;
    padding: 50px 50px 112px;
    background-color: #fbfbfb;
    @media (max-width: 767px) {
        padding: 50px 15px 40px;
    }
`;

export const IconList = styled.ul`
    ${generalFlexStyles};
    padding: 0;
    margin: 0 0 32px;
    width: max-content;
`;

export const IconItem = styled.li`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;

export const Description = styled.article`
    ${generalFlexStyles};
    flex-direction: column;
    margin-bottom: 70px;
    @media (max-width: 767px) {
        margin-bottom: 24px;
    }
    @media (max-width: 640px) {
        h1 {
            max-width: 400px;
        }
        p {
            max-width: 550px;
            margin: 0 auto;
        }
    }
    @media (max-width: 380px) {
        h1 {
            max-width: 340px;
        }
        p {
            max-width: 320px;
        }
    }
`;
