import React from 'react';
import uuid from 'react-uuid';
import { Title, SubTitle, RoundCount } from 'shared/ui';
import { TextAlignType } from 'shared/types/general';
import { RoundType } from 'shared/ui/RoundCount/RoundCount.types';
import howItWorks from 'shared/assets/how-it-work.png';
import { Container, Content, Description, Image, Info, List, Item, TextContainer } from './HowItWorkSection.style';

const infoList = [
    {
        title: 'Upload document',
        description: 'Click to browse or drag and drop an Excel / CSV file of your phone numbers.',
    },
    {
        title: 'Map phone field',
        description: "Select the column that contains your document's phone numbers.",
    },
    {
        title: 'Get the report',
        description:
            'PhoneCheckr will do all the work. You’ll get a detailed report of every phone number in your file.',
    },
];

const HowItWorkSection: React.FC = () => {
    return (
        <Container id="section-how-it-work">
            <Content>
                <Description>
                    <Title as="h2" text="How it works" />
                </Description>
                <Info>
                    <Image src={howItWorks} alt="How it works" />
                    <List>
                        {infoList.map((item, index) => {
                            return (
                                <Item key={uuid()}>
                                    <RoundCount type={RoundType.SUCCESS} count={index + 1} />
                                    <TextContainer>
                                        <Title
                                            as="h4"
                                            size="20px"
                                            margin="0 0 12px"
                                            text={item.title}
                                            textAlign={TextAlignType.LEFT}
                                        />
                                        <SubTitle size="16px" text={item.description} textAlign={TextAlignType.LEFT} />
                                    </TextContainer>
                                </Item>
                            );
                        })}
                    </List>
                </Info>
            </Content>
        </Container>
    );
};

export default HowItWorkSection;
