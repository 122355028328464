import React, { memo } from 'react';
import { VectorIcon, Title, SubTitle } from 'shared/ui';
import { TextAlignType } from 'shared/types/general';
import { IFeaturesCardProps } from './FeaturesCard.types';
import { Container } from './FeaturesCard.style';

const FeaturesCardComponent: React.FC<IFeaturesCardProps> = ({ title, description, icon }) => {
    return (
        <Container>
            <VectorIcon size={[56, 56]} icon={icon} />
            <Title as="h3" size="20px" text={title} margin="28px 0 12px" textAlign={TextAlignType.LEFT} />
            <SubTitle size="16px" text={description} textAlign={TextAlignType.LEFT} />
        </Container>
    );
};

export const FeaturesCard = memo(FeaturesCardComponent);
