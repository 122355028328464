import React from 'react';
import { toast } from 'react-toastify';
import { Alert } from 'shared/ui';
import { AlertType } from 'shared/ui/Alert/Alert.types';

export const alertError = (title?: string, description?: string): React.ReactNode =>
    toast(<Alert title={title} description={description} type={AlertType.ERROR} />, {
        position: toast.POSITION.TOP_RIGHT,
    });

export const alertSuccess = (title?: string, description?: string): React.ReactNode =>
    toast(<Alert title={title} description={description} type={AlertType.SUCCESS} />, {
        position: toast.POSITION.TOP_RIGHT,
    });

export const alertWarning = (title?: string, description?: string): React.ReactNode =>
    toast(<Alert title={title} description={description} type={AlertType.WARNING} />, {
        position: toast.POSITION.TOP_RIGHT,
    });
