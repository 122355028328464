import styled from 'styled-components';
import { Button } from 'shared/ui';

export const Container = styled.section`
    width: 100%;
    min-height: calc(100vh - 151px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 56px;
    background-color: #fbfbfb;

    @media (max-width: 767px) {
        padding: 70px 50px 50px;
    }
    @media (max-width: 415px) {
        padding: 70px 15px 50px;
    }
`;

export const CloseButton = styled(Button)`
    position: absolute;
    right: 32px;
    top: 95px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8a26ee;
    padding: 10px;

    @media (max-width: 767px) {
        padding: 8px;
        top: 67px;
        right: 15px;
    }
`;
