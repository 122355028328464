import styled from 'styled-components';

export const Container = styled.span`
    margin: 0;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
`;
