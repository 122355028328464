import React from 'react';
import * as types from 'shared/consts/modalsTypes';
import { useAppSelector } from 'shared/lib/hooks/redux';
import ModalPayment from 'entities/Upload/ui/ModalPayment/ModalPayment';

const ModalsPortal: React.FC = () => {
    const { modalType } = useAppSelector(state => state.modalsReducer);

    return <>{modalType && modalType === types.MODAL_TYPE_PAYMENT && <ModalPayment />}</>;
};

export default ModalsPortal;
