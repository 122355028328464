import styled from 'styled-components';
import { Input } from 'shared/ui';

export const Container = styled.div`
    width: 100%;
    min-height: 467px;
    background: #ffffff;
    border: 1px solid #edeff2;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px 24px;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;

    @media (max-width: 415px) {
        padding: 0 6px;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const Label = styled.p`
    padding: 0;
    margin: 0 0 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7e868d;
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 32px;

    @media (max-width: 415px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const Actions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const InputCroup = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 12px;
`;

export const Value = styled.div`
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
    color: #121217;

    @media (max-width: 415px) {
        margin-bottom: 5px;
    }
`;

export const SubValue = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #7e868d;
    margin-left: 4px;

    @media (max-width: 415px) {
        margin: 0;
        line-height: 1.2;
    }
`;

export const List = styled.ul`
    padding: 0;
    margin: 0;
`;

export const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
`;

export const Name = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7e868d;
    margin-left: 12px;
`;

export const EmailInput = styled(Input)`
    width: 100%;
    padding: 10px 12px;
    margin: 0;
    background: #f6f6f6;
    border-radius: 12px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #121217;

    &:hover {
        background: #EDEFF2;
    }
`;

export const Error = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 0 4px;
`;

export const CloseButton = styled.span`
    position: relative;
    margin: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 0.5px solid transparent;
    border-radius: 40px;
    &::after,
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 0.5px;
        background: #e11900;
        transform: rotate(45deg);
        border-radius: 5px;
        top: 8px;
        left: 0;
    }
    &::after {
        transform: rotate(-45deg);
    }
`;

export const ErrorMessage = styled.span`
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #e11900;
    margin: 0;
`;
