import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from 'shared/ui';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import { Container, StyledButton } from './UploadFilesButton.style';
import { IUploadFilesButtonProps } from './UploadFilesButton.types';

const UploadFilesButton: React.FC<IUploadFilesButtonProps> = ({ config, onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: config,
        onDrop: onDrop,
    });

    return (
        <Container {...getRootProps()}>
            <Input {...getInputProps()} />
            <StyledButton colorType={ButtonColorType.LIGHT_BLUE}>Add file</StyledButton>
        </Container>
    );
};

export default UploadFilesButton;
