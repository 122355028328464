import React, { ButtonHTMLAttributes } from 'react';
import { ValueOf } from 'shared/types/general';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    isDisabled?: boolean;
    colorType: ButtonColorType;
    formType?: ButtonFormType;
    width?: string;
    height?: string;
    onClick?: (event: React.MouseEvent) => void;
    children?: React.ReactNode;
}

export interface IButtonStylesProps extends IButtonProps {
    disabled?: boolean;
}

export const ButtonColorType = {
    BLUE: 'Blue',
    LIGHT_BLUE: 'LightBlue',
    WHITE: 'White',
    BASE: 'Base',
    GREEN: 'Green',
} as const;

type ButtonColorType = ValueOf<typeof ButtonColorType>;

export const ButtonFormType = {
    ROUND: 'Round',
    SIMPLE: 'Simple',
} as const;

type ButtonFormType = ValueOf<typeof ButtonFormType>;
