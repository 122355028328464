import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    padding: 20px;
    @media (max-width: 767px) {
        padding: 10px 15px 0;
    }
`;

export const Content = styled.div`
    ${generalFlexStyles};
    width: 100%;
    background-color: #e7f5fb;
    border-radius: 12px;
`;

export const Decorator = styled.div`
    ${generalFlexStyles};
    width: 100%;
    background-image: url(/assets/features-bg-image.png);
    background-repeat: no-repeat;
    background-position: top 5% right 10%;
    @media (max-width: 1400px) {
        background-position: top 5% right 5%;
    }
    @media (max-width: 767px) {
        background-position: top 2% right 2%;
    }
    @media (max-width: 415px) {
        background-image: none;
    }
`;

export const InnerContent = styled.div`
    ${generalFlexStyles};
    width: 100%;
    max-width: 1164px;
    padding: 88px 0;
    @media (max-width: 767px) {
        max-width: calc(100vw - 30px);
        padding: 45px 0;
    }
    @media (max-width: 415px) {
        max-width: calc(100vw - 30px);
        padding: 15px;
    }
`;

export const List = styled.ul`
    ${generalFlexStyles};
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 2;
    @media (max-width: 1199px) {
        flex-direction: column;
    }
`;

export const Item = styled.li`
    margin-right: 24px;
    width: calc((100% - 48px) / 3);
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 1199px) {
        margin: 0 auto 24px;
        width: 372px;
        &:last-child {
            margin: 0 auto;
        }
    }
    @media (max-width: 415px) {
        width: 100%;
    }
`;
