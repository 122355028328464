import React, { useEffect } from 'react';
import FeaturesSection from 'entities/Landing/ui/FeaturesSection/FeaturesSection';
import PricingSection from 'entities/Landing/ui/PricingSection/PricingSection';
import HowItWorkSection from 'entities/Landing/ui/HowItWorkSection/HowItWorkSection';
import ExampleSection from 'entities/Landing/ui/ExampleSection/ExampleSection';
import FaqSection from 'entities/Landing/ui/FAQSection/FAQSection';
import UploadSection from 'entities/Upload/ui/UploadSection/UploadSection';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { sessionNew } from 'entities/Upload/store/upload/reducers/ActionCreator';

const HomePage: React.FC = () => {
    const { token } = useAppSelector(state => state.uploadReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(sessionNew(token ?? ''));
    }, []);

    return (
        <>
            <UploadSection />
            <FeaturesSection />
            <PricingSection />
            <HowItWorkSection />
            <ExampleSection />
            <FaqSection />
        </>
    );
};

export default HomePage;
