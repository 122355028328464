import React from 'react';
import Layout from 'entities/Upload/ui/Layout/Layout';
import CheckingFiles from 'entities/Upload/ui/CheckingFiles/CheckingFiles';

const CheckPage: React.FC = () => {
    return (
        <Layout>
            <CheckingFiles />
        </Layout>
    );
};

export default CheckPage;
