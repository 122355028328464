import React from 'react';
import Layout from 'entities/Upload/ui/Layout/Layout';
import DownloadSection from 'entities/Download/ui/DownloadSection/DownloadSection';

const DownloadPage = () => {
    return (
        <Layout>
            <DownloadSection />
        </Layout>
    );
};

export default DownloadPage;
