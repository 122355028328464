import styled from 'styled-components';
import { ICheckingFilesStyleProps } from './CheckingFiles.types';

export const Container = styled.div`
    width: 100%;
    max-width: 808px;
`;

export const Description = styled.article<ICheckingFilesStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: ${({ bigMargin }) => (bigMargin ? '80px' : '60px')};
    @media (max-width: 415px) {
        margin-bottom: 30px;
    }
`;

export const ProgressContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
`;

export const List = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0 0 4px;
    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 415px) {
        flex-wrap: wrap;
    }
`;

export const Item = styled.li`
    width: 80px;
    margin: 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:last-child {
        margin: 0;
    }

    @media (max-width: 415px) {
        flex-wrap: wrap;
        margin: 0 15px 20px;
        &:last-child {
            margin: 0 15px 20px;
        }
    }
`;

export const Type = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #121217;
    margin-top: 10px;
`;

export const IndicatorContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IndicatorInnerContainer = styled.div`
    height: max-content;
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

export const CheckingSuccess = styled.div`
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 4px solid #edeff2;
`;

export const Indicator = styled.span`
    font-weight: 300;
    font-size: 72px;
    line-height: 1;
    color: #121217;
    margin-left: 18px;
`;

export const Percent = styled.span`
    font-weight: 500;
    font-size: 20px;
    line-height: 1.8;
    color: #7e868d;
`;
