import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const Container = styled(ToastContainer)`
    .Toastify__toast-body {
        padding: 0;
    }
    .Toastify__toast {
        min-height: min-content;
        padding: 0;
    }
`;
