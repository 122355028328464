import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'widgets/Header/Header';
import Footer from 'widgets/Footer/Footer';
import AlertPortal from 'widgets/AlertPortal/AlertPortal';
import useOptimizeAB from "shared/lib/hooks/useOptimizeAB";
import { Main } from './Layout.style';

const Layout: React.FC = () => {
    const location = useLocation();
    const variant = useOptimizeAB('experimentId');

    useEffect(() => {
        ReactGA.send({
            hitType: 'page_view',
            page: location.pathname,
        });
    }, [location]);

    return (
        <>
            <AlertPortal />
            <Header />
            <Main id="main">
                <Outlet />
            </Main>
            <Footer />
        </>
    );
};

export default Layout;
