import React, { FC, memo } from 'react';
import { BasicLoader } from 'shared/ui';
import { PropsTable } from './PricingTable.types';
import { BTable, BTableHead, BTableTr, BTableTh, BTableBody, BTableTd, LoaderContainer } from './PricingTable.style';

const defaultProps: PropsTable = {
    data: [],
    columns: [],
};

const TableComponent: FC<PropsTable> = ({ data, columns, showLoader, children }) => {
    return (
        <>
            <BTable>
                <BTableHead>
                    <BTableTr>
                        {columns.map((item: any, index: number) => {
                            if (item.templateHead) {
                                return (
                                    <BTableTh key={index} textAlign={item.textAlign}>
                                        {item.templateHead(item)}
                                    </BTableTh>
                                );
                            }

                            return (
                                <BTableTh key={index} textAlign={item.textAlign}>
                                    {item.label}
                                </BTableTh>
                            );
                        })}
                    </BTableTr>
                </BTableHead>
                {!showLoader && (
                    <BTableBody>
                        {data.map((item, index) => {
                            if (columns.length) {
                                return (
                                    <BTableTr key={index}>
                                        {columns.map((column: any, i: number) => (
                                            <BTableTd key={i} width={column.widthColumn}>
                                                {column.templateRow(item, column)}
                                            </BTableTd>
                                        ))}
                                    </BTableTr>
                                );
                            }

                            return null;
                        })}
                    </BTableBody>
                )}
            </BTable>
            {showLoader && (
                <LoaderContainer>
                    <BasicLoader isCenter />
                </LoaderContainer>
            )}
            {children}
        </>
    );
};

TableComponent.defaultProps = defaultProps;

export const PricingTable = memo(TableComponent);
