export const getFileDataAsBase64String = (file: File): Promise<string> =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsBinaryString(file);

        reader.onload = () => {
            const encoded = btoa(reader.result as string);

            resolve(encoded);
        };

        reader.onerror = error => reject(error);
    });
