import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { IMobileNavigationStylesProps } from './MobileNavigation.types';

export const Button = styled.button`
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: transparent;
    cursor: pointer;
    margin-left: 110px;
`;

const buttonStyles = css`
    background-color: #000000;
    width: 15px;
    height: 2px;
    transform: rotate(180deg);
    transition: 0.5s ease-in-out;
    border-radius: 2px;
`;

export const ButtonContent = styled.span<IMobileNavigationStylesProps>`
    display: flex;
    position: relative;
    ${buttonStyles};
    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 4px;
        ${buttonStyles};
    }
    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 4px;
        ${buttonStyles};
    }
    ${({ isActive }) =>
        isActive &&
        css`
            background: transparent;
            &:before {
                transform: rotate(45deg);
                top: 0;
            }
            &:after {
                transform: rotate(-45deg);
                top: 0;
            }
        `}
`;

export const Navigation = styled.nav`
    position: fixed;
    left: 0;
    right: 0;
    top: 52px;
    width: 100vw;
    height: calc(100vh - 52px);
    background-color: #ffffff;
    z-index: 10;
`;

export const NavList = styled.ul`
    margin: 0;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const NavItem = styled.li`
    margin: 0;
    padding: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
        margin-top: 20px;
    }
`;

export const Link = styled(HashLink)`
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7e868d;
    text-decoration: none;
`;
