import styled, { css } from 'styled-components';
import { IMarkerProps, MarkerType } from './Marker.types';

export const Container = styled.div<IMarkerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ markerSize }) => `${markerSize}px`};
    height: ${({ markerSize }) => `${markerSize}px`};
    background: transparent;
    border-radius: 100%;
    ${({ status }) =>
        (status === MarkerType.COMPLETED &&
            css`
                background: #00ac6e;
            `) ||
        (status === MarkerType.ACTIVE &&
            css`
                border: 2px solid #1268fb;
            `) ||
        (status === MarkerType.NOT_PROCESSED &&
            css`
                border: 2px solid #dee1e5;
            `)}
`;
