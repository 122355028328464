import styled from 'styled-components';
import { TextAlignType } from 'shared/types/general';
import { IStylesProps } from './Title.types';

export const Container = styled.h1<IStylesProps>`
    font-weight: 600;
    font-size: ${({ size }) => size ?? '40px'};
    line-height: 1.2;
    text-align: ${({ textAlign }) => textAlign ?? TextAlignType.CENTER};
    color: ${({ color }) => color ?? '#121217'};
    margin: ${({ margin }) => margin ?? 0};
`;
