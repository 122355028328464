import { useState } from 'react';
import useIsomorphicLayoutEffect from 'shared/lib/hooks/useIsomorphicLayoutEffect';
import useEventListener from 'shared/lib/hooks/useEventListener';

interface WindowSize {
    width: number;
    height: number;
}

function useWindowResizeObserver(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
    });

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEventListener('resize', handleSize);

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleSize();
    }, []);

    return windowSize;
}

export default useWindowResizeObserver;
