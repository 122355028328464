import React from 'react';
import { CURRENT_YEAR } from 'shared/consts/time';
import { PATHS } from 'shared/consts/paths';
import { Container, Navigation, NavList, NavItem, Link, Copyright } from './Footer.style';

const Footer: React.FC = () => {
    return (
        <Container>
            <Navigation>
                <NavList>
                    <NavItem>
                        <Copyright>© {CURRENT_YEAR} PhoneCheckr. All rights reserved.</Copyright>
                    </NavItem>
                    <NavItem>
                        <Link to={PATHS.TERMS_OF_USE}>Terms of Service</Link>
                    </NavItem>
                    <NavItem>
                        <Link to={PATHS.PRIVACY_POLICY}>Privacy Policy</Link>
                    </NavItem>
                </NavList>
            </Navigation>
        </Container>
    );
};

export default Footer;
