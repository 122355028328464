import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ReactGA from 'react-ga4';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { alertError } from 'shared/lib/functions/alert';
import { PATHS } from 'shared/consts/paths';
import { SubTitle, Title } from 'shared/ui';
import UploadFileCard from 'entities/Upload/ui/UploadFileCard/UploadFileCard';
import StepsCount from 'entities/Upload/ui/StepsCount/StepsCount';
import { addFile, sessionInfo, removeFile, updatePhoneKey } from 'entities/Upload/store/upload/reducers/ActionCreator';
import UploadFilesButton from '../UploadFilesButton/UploadFilesButton';
import { uploadSlice } from 'entities/Upload/store/upload/reducers/Upload';
import { ISelectedField, Type } from 'entities/Upload/ui/UploadFileCard/UploadFileCard.types';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import { Container, Description, List, Item, Actions, AddFileActions, StyledButton } from './UploadFiles.style';

const UploadFiles = () => {
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [loadingFile, setLoadingFile] = useState<string | null>(null);
    const [selectedFields, setSelectedFields] = useState<ISelectedField[] | []>([]);
    const { loadedFiles, token } = useAppSelector(state => state.uploadReducer);
    const { removeLoadedFile } = uploadSlice.actions;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleDrop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach(file => {
            if (file.type !== 'text/csv') {
                alertError('', 'Please use the file type: csv');

                return;
            }

            if (file.size === 0) {
                alertError('', 'File is Empty');
            } else {
                const formData = new FormData();

                formData.append('file', file);

                if (token) {
                    setLoadingFile(file.name);
                    const addFileStatus = dispatch(
                        addFile({
                            formData,
                            token,
                        }),
                    );

                    addFileStatus.then(() => {
                        ReactGA.event({
                            category: 'PhoneCheckr',
                            action: 'File Uploaded',
                        });

                        setLoadingFile(null);
                        dispatch(sessionInfo(token));
                    });
                }
            }
        });
    };

    const handleSetSelectedField = (field: ISelectedField) => {
        const fields = selectedFields.filter(item => item.id !== field.id);

        fields.push(field);

        setSelectedFields(fields);
    };

    const handleRemoveFile = (id: number): void => {
        if (id && token) {
            const removeFileStatus = dispatch(
                removeFile({
                    id,
                    token,
                }),
            );

            removeFileStatus.then(() => {
                const fields = selectedFields.filter(item => item.id !== id);

                setSelectedFields(fields);

                dispatch(removeLoadedFile(id));
            });
        }
    };

    const handleCheckFiles = () => {
        if (token) {
            let count = 0;

            selectedFields.forEach(item => {
                const updatePhoneKeyStatus = dispatch(
                    updatePhoneKey({
                        token,
                        id: item.id,
                        index: { phone_key_index: item.index },
                    }),
                );

                updatePhoneKeyStatus.then(() => {
                    count = count + 1;

                    if (count === selectedFields.length) {
                        navigate(PATHS.CHECK);
                    }
                });
            });
        }
    };

    useEffect(() => {
        if (selectedFields.length === loadedFiles.length && loadedFiles.length !== 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [selectedFields, loadedFiles]);

    return (
        <Container>
            <StepsCount>Step 1/3</StepsCount>
            <Description>
                <Title text="Upload files" margin="0 0 8px 0" />
                <SubTitle text="Select the column that contains your document's phone numbers" />
            </Description>
            <List>
                {loadedFiles.map((item, index) => {
                    return (
                        <Item key={uuid()}>
                            <UploadFileCard
                                type={Type.SUCCESS}
                                id={item.id}
                                index={index + 1}
                                title={item.original_name}
                                value={item.total_rows_count}
                                options={item.headers}
                                previews={item.previews}
                                selectedFields={selectedFields}
                                setSelectedField={handleSetSelectedField}
                                removeFile={handleRemoveFile}
                            />
                        </Item>
                    );
                })}
            </List>
            {loadingFile && (
                <List>
                    <Item>
                        <UploadFileCard type={Type.LOADING} index={0} title={loadingFile} />
                    </Item>
                </List>
            )}
            <Actions>
                <AddFileActions>
                    <UploadFilesButton onDrop={handleDrop} />
                </AddFileActions>
                <StyledButton colorType={ButtonColorType.BLUE} disabled={isDisable} onClick={handleCheckFiles}>
                    Let’s check
                </StyledButton>
            </Actions>
        </Container>
    );
};

export default UploadFiles;
