import styled, { css } from 'styled-components';
import { IAccordionStylesProps } from './Accordion.types';

export const Container = styled.div`
    width: 100%;
    border: 1px solid #edeff2;
    box-shadow: 0 10px 40px rgba(112, 123, 142, 0.1);
    border-radius: 12px;
`;

export const Content = styled.div`
    width: 100%;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px 24px 24px;
    cursor: pointer;
`;

export const Title = styled.p`
    margin: 0 20px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #121217;
`;

export const Icon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: #f6f6f6;
    border-radius: 100%;
    position: relative;
`;

export const InnerContainer = styled.div<IAccordionStylesProps>`
    width: 100%;
    ${({ isActive }) =>
        isActive &&
        css`
            ${Header} {
                padding: 24px 20px 16px 24px;
            }

            ${Content} {
                padding: 0 60px 24px 24px;
            }
        `}
`;
