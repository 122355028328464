import React, { memo } from 'react';
import classNames from 'classnames';
import { Container } from './Button.style';
import { IButtonProps } from './Button.types';

const ButtonComponent: React.FC<IButtonProps> = ({
    className,
    children,
    isDisabled,
    width,
    height,
    colorType,
    formType,
    onClick,
    ...rest
}) => {
    return (
        <Container
            width={width}
            height={height}
            colorType={colorType}
            formType={formType}
            className={classNames(className)}
            disabled={isDisabled}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Container>
    );
};

export const Button = memo(ButtonComponent);
