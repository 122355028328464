import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { SubTitle, Title, VectorIcon } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { getDownloadInfo } from 'entities/Upload/store/upload/reducers/ActionCreator';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import { Container, CompletedLogo, Description, UploadButton } from './DownloadSection.style';

const DownloadSection: React.FC = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { zip_file, type } = useAppSelector(state => state.uploadReducer);

    const downloadViaBrowser = () => {
        if (zip_file.length && type) {
            ReactGA.event({
                category: 'PhoneCheckr',
                action: 'Report Downloaded',
                label: type,
            });

            window.open(zip_file, '_blank');
        }
    };

    useEffect(() => {
        downloadViaBrowser();
    }, [zip_file]);

    useEffect(() => {
        if (id) {
            dispatch(getDownloadInfo(id));
        }
    }, []);

    return (
        <Container>
            <CompletedLogo>
                <VectorIcon icon="completed-logo" size={[33, 40]} />
            </CompletedLogo>
            <Description>
                <Title text="Thank you!" size="64px" margin="0 0 10px 0" />
                <SubTitle text="Your download should start automatically. If it doesn’t, please use the download button below." />
            </Description>
            <UploadButton colorType={ButtonColorType.BLUE} onClick={() => downloadViaBrowser()}>
                Download files
            </UploadButton>
        </Container>
    );
};

export default DownloadSection;
