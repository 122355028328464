import React from 'react';
import { VectorIcon } from 'shared/ui';
import { PATHS } from 'shared/consts/paths';
import { Link } from 'react-router-dom';
import { ButtonColorType } from 'shared/ui/Button/Button.types';
import { ILayoutProps } from './Layout.types';
import { Container, CloseButton } from './Layout.style';

const Layout: React.FC<ILayoutProps> = ({ children }) => {
    return (
        <Container>
            <Link to={PATHS.HOME}>
                <CloseButton colorType={ButtonColorType.WHITE}>
                    <VectorIcon size={[20, 20]} icon="close-icon" />
                </CloseButton>
            </Link>
            {children}
        </Container>
    );
};

export default Layout;
