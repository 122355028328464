import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'app/App';
import reportWebVitals from 'app/reportWebVitals';
import ReactGA from 'react-ga4';
import { PostHogProvider} from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import { GA_TRACKING_ID, GTM_TRACKING_ID } from 'shared/consts/analytics';
import { ENV_DEVELOPMENT, POSTHOG_HOST, POSTHOG_KEY } from "./shared/consts/common";

declare global {
    interface Window {
        [key: string]: any;
    }
}

ReactGA.initialize(GA_TRACKING_ID);
TagManager.initialize({
    gtmId: GTM_TRACKING_ID
});

const options = {
    api_host: POSTHOG_HOST,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <>
        {process.env.NODE_ENV === ENV_DEVELOPMENT ? (
            <BrowserRouter>
                <PostHogProvider
                    apiKey={ POSTHOG_KEY }
                    options={ options }
                >
                    <App />
                </PostHogProvider>
            </BrowserRouter>
        ) : (
            <React.StrictMode>
                <BrowserRouter>
                    <PostHogProvider
                        apiKey={ POSTHOG_KEY }
                        options={ options }
                    >
                        <App />
                    </PostHogProvider>
                </BrowserRouter>
            </React.StrictMode>
        )}
    </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
