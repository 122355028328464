import React from 'react';
import { useAppSelector } from 'shared/lib/hooks/redux';
import { SubTitle, Title, VectorIcon } from 'shared/ui';
import logoSalesmsg from 'shared/assets/salesmsg.png';
import logoSalesmsgWhite from 'shared/assets/salesmsg-white.png';
import {
    CompletedLogo,
    Container,
    Description,
    GreenButton,
    Image,
    Info,
    InfoDescription,
    Label,
    LightBlueButton,
    LinkToSalesmsg,
} from './ThankSection.style';
import ReactGA from 'react-ga4';

const ThankSection: React.FC = () => {
    const { send_to, coupon } = useAppSelector(state => state.uploadReducer);
    const isShowBlueBanner = false;

    const getCode = (): string => {
        if (coupon) {
            return coupon.url.slice(coupon.url.indexOf('=') + 1);
        }

        return '';
    };

    const navigateToSalesmsg = () => {
        ReactGA.event({
            category: 'PhoneCheckr',
            action: 'Go to Salesmsg',
        });
    };

    const toRegistrationSalesmsg = () => {
        ReactGA.event({
            category: 'PhoneCheckr',
            action: 'Go to Registration on Salesmsg',
        });
    };

    return (
        <Container>
            <CompletedLogo>
                <VectorIcon icon="completed-logo" size={[33, 40]} />
            </CompletedLogo>
            <Description>
                <Title text="Thank you! Please check your email for your report." size="64px" margin="0 0 10px 0" />
                <SubTitle text={`We’ve sent the report to ${send_to}`} />
            </Description>
            <Info isShowBlueBanner={isShowBlueBanner}>
                <LinkToSalesmsg
                    onClick={navigateToSalesmsg}
                    href="https://www.salesmessage.com/?utm_source=phonecheckr&utm_medium=link&utm_campaign=main"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Image src={isShowBlueBanner ? logoSalesmsgWhite : logoSalesmsg} alt="Logo Salesmsg" />
                </LinkToSalesmsg>
                <InfoDescription>
                    <Title as="h2" text="We have a gift for you" size="32px" margin="0 0 8px 0" />
                    <SubTitle
                        text={`Use this coupon and get ${coupon?.percent_off}% of your first month on Salesmsg`}
                    />
                </InfoDescription>
                <GreenButton
                    onClick={toRegistrationSalesmsg}
                    href={coupon?.url}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    {getCode()}
                </GreenButton>
            </Info>
            <LightBlueButton
                onClick={navigateToSalesmsg}
                href="https://www.salesmessage.com/?utm_source=phonecheckr&utm_medium=link&utm_campaign=main"
                target="_blank"
                rel="noreferrer noopener"
            >
                <VectorIcon size={[16, 16]} icon="play-icon" />
                <Label>What is Salesmsg</Label>
            </LightBlueButton>
        </Container>
    );
};

export default ThankSection;
