import styled, { css } from 'styled-components';

const generalFlexStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    ${generalFlexStyles};
    width: 100%;
    max-width: 926px;
    margin: 0 auto;
    padding: 100px 20px;
    @media (max-width: 767px) {
        padding: 55px 20px;
    }
    @media (max-width: 415px) {
        padding: 55px 15px 15px;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 40px;

    @media (max-width: 767px) {
        min-height: 140px;
    }
`;

export const Label = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: #7e868d;
    margin-bottom: 15px;
    @media (max-width: 415px) {
        margin-bottom: 10px;
    }
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 15px;

    @media (max-width: 767px) {
        align-items: center;
        justify-content: center;
        min-height: 46px;
    }
    @media (max-width: 415px) {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 10px;
    }
`;

export const Value = styled.div`
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
    color: #121217;
    @media (max-width: 415px) {
        margin-bottom: 5px;
    }
`;

export const SubValue = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #7e868d;
    margin-left: 4px;

    @media (max-width: 769px) {
        line-height: 16px;
        margin-top: 10px;
    }
    @media (max-width: 415px) {
        margin: 0;
        line-height: 1.2;
    }
`;

export const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7e868d;
    margin: 0;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #121217;
    margin: 0;
    @media (max-width: 415px) {
        display: flex;
        max-width: 84px;
    }
`;
